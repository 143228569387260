import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import axiosClient from '../axiosClient';
import { useStateContext } from '../contexts/contextprovider';
import styled from 'styled-components';
import ProfileDetails from '../views/ProfileDetails'; // Import the ProfileDetails component

// Define styled components
const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainContent = styled.main`
  width: 75%;
  padding: 20px;
`;

export default function DefaultLayout() {
  const { user, token, setUser, setToken } = useStateContext();
  const [subjectid,setSubjectID] = useState("")

  useEffect(() => {
    if (token) {
      // axiosClient.get('/user').then(({ data }) => {
      //   setUser(data);
      // });
      axiosClient.get('/subject?limit=3&page=1').then(({ data }) => {
        console.log(data)
      });
    }
  }, [token]);

  if (!token) {
    return <Navigate to='/home' />;
  }

  const onLogout = (ev) => {
    ev.preventDefault();
    setUser(null);
    setToken(null);
    // axiosClient.get('/logout').then(() => {
    // });
  };

  const subID = (ev) =>{
    setSubjectID(ev)
  //  console.log("Sub iiiii ddddd ",ev)
  }

  return (
    <Container>
      <ProfileDetails subid={subID}  user={user} onLogout={onLogout}/>
      <MainContent>
        
      <Outlet context={{ selectedSubject: subjectid }} />
      </MainContent>
    </Container>
  );
}
