import React, { useState, useEffect } from "react";
import axiosClient from "../axiosClient";
import {
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { storage } from "../Components/OtherStuff/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { styled } from "@mui/system";
import { Upload as UploadIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteFile } from "../utils/firebaseUtils";

const SubmitAnswers = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [studentAnswers, setStudentAnswers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const userId = localStorage.getItem("USER_ID");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teacherFeedback, setTeacherFeedback] = useState(null);
  const [formData, setFormData] = useState({
    _id: null,
    teacher_link: null,
    mark: null,
    feedback: null,
    status: null,
  });
  const statuses = ["to-do-teacher", "draft", "submitted"];
  const UploadButton = styled(Button)({
    marginRight: 16,
  });
  const [openRecorrection, setOpenRecorrection] = useState(false);
  const [recorrectionItem, setRecorrectionItem] = useState({});
  const [openSubmitAll, setOpenSubmitAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [answerPagination, setAnswerPagination] = useState({
    page: 0,
    limit: 25
  });

  useEffect(() => {
    fetchData();
  }, [currentTab, searchQuery, answerPagination]);

  const fetchData = () => {
    setIsLoading(true);
    const search = searchQuery ? `&search=${searchQuery}` : "";
    axiosClient
      .get(`/paper-enroll?limit=${answerPagination.limit}&page=${answerPagination.page + 1}&teacher_id=${userId}${search}`)
      .then((response) => {
        if (response.data.status) {
          const enrollPapers = response.data.data.enrollPapers || [];
          setStudentAnswers(enrollPapers);
          setTotalAnswers(response.data.data.recordsTotal);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Error fetching subjects", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const filteredAnswers = studentAnswers.filter(
    (answer) => answer.status === statuses[currentTab]
  );

  const handleChangePage = (event, newPage) => {
    setAnswerPagination({
      ...answerPagination,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setAnswerPagination({
      ...answerPagination,
      limit: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setAnswerPagination({ ...answerPagination, page: 0 });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSearchQuery("");
    setAnswerPagination({ ...answerPagination, page: 0 });
  };

  const handleDownloadAnswer = (studentLink) => {
    window.open(studentLink, "_blank");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "mark" ? Number(value) : value,
    }));
  };

  const uploadFile = async (file, path) => {
    if (file) {
      const fileRef = ref(storage, path);
      try {
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        return url;
      } catch (error) {
        console.error("Error uploading file:", error);
        throw new Error("Error uploading file. Please try again.");
      }
    } else {
      throw new Error("No file selected.");
    }
  };

  const handleUploadFeedback = (id, teacher_link, status) => {
    setFormData({
      _id: id,
      teacher_link: teacher_link,
      mark: null,
      feedback: null,
      status: "draft",
    });
    setOpen(true);
  };

  const handleClose = () => {
    alert(JSON.stringify(formData));
    setFormData({
      _id: null,
      teacher_link: null,
      mark: null,
      feedback: null,
      status: null,
    });
    setOpen(false);
  };

  const handleUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setTeacherFeedback(selectedFile);
    }
  };

  const clickUpload = async (status) => {
    if (!teacherFeedback) {
      toast.error("Please select a file before uploading!!!");
      return;
    }

    setLoading(true);
    try {
      const timestamp = Date.now();
      const fileName = `${timestamp}_${teacherFeedback.name}`;
      const teacher_link = await uploadFile(
        teacherFeedback,
        `pdfs/${fileName}`
      );

      if (formData.teacher_link) {
        const fileRef = ref(storage, formData.teacher_link);
        try {
          await getDownloadURL(fileRef);
          await deleteFile(formData.teacher_link);
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            console.log("File does not exist in Firebase storage");
          } else {
            console.error("Error checking/deleting file:", error);
          }
        }
      }

      const updatedFormDataToSubmit = {
        ...formData,
        teacher_link,
        status,
      };

      if (status === "submitted" && !updatedFormDataToSubmit.mark) {
        toast.error("Mark is required when submitting!!!");
        return;
      }

      if (studentAnswers) {
        console.log("answer data", updatedFormDataToSubmit);
        await axiosClient
          .put("/paper-enroll/update", updatedFormDataToSubmit)
          .then((response) => {
            if (response.data.status) {
              toast.success("Upload Feedback successfully!");
              fetchData();
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            console.log("upload error" + err);
          });
      }
      setOpen(false);
    } catch (err) {
      console.log("upload error" + err);
    } finally {
      setLoading(false);
    }
  };

  const clickSubmit = async (id) => {
    setIsLoading(true);
    try {
      const updatedStatus = {
        _id: id,
        status: "submitted",
      };

      if (studentAnswers) {
        console.log("answer data", updatedStatus);
        await axiosClient
          .put("/paper-enroll/update", updatedStatus)
          .then((response) => {
            if (response.data.status) {
              toast.success("Feedback submitted successfully!");
              fetchData();
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            console.log("submit error" + err);
          });

        setTeacherFeedback(null);
      }
      setOpen(false);
    } catch (err) {
      console.log("submit error" + err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecorrection = async (id, mark, teacher_link) => {
    await setRecorrectionItem({ _id: id, teacher_link, mark });
    setOpenRecorrection(true);
  };

  const handleCloseRecorrection = async () => {
    await setRecorrectionItem({});
    setOpenRecorrection(false);
  };

  const clickRecorrection = async () => {
    setLoading(true);

    try {
      const recorreectionItem = {
        _id: recorrectionItem._id,
        mark: null,
        teacher_link: null,
        feedback: "Recorrection Item",
        status: "to-do-teacher",
      };

      if (recorrectionItem.teacher_link) {
        const fileRef = ref(storage, recorrectionItem.teacher_link);
        try {
          await getDownloadURL(fileRef);
          await deleteFile(recorrectionItem.teacher_link);
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            console.log("File does not exist in Firebase storage");
          } else {
            console.error("Error checking/deleting file:", error);
          }
        }
      }

      if (studentAnswers) {
        await axiosClient
          .put("/paper-enroll/update", recorreectionItem)
          .then((response) => {
            if (response.data.status) {
              toast.success("Following task set to recorrection successfully!");
              fetchData();
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            console.log("recorrection error" + err);
          });

        setTeacherFeedback(null);
      }
      setOpenRecorrection(false);
    } catch (err) {
      console.error("recorrection error" + err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitAll = async () => {
    setOpenSubmitAll(true);
  };

  const handleCloseSubmitAll = async () => {
    setOpenSubmitAll(false);
  };

  const clickSubmitAll = async () => {
    const draftAnswers = studentAnswers.filter(
      (answer) => answer.status === "draft"
    );
    if (draftAnswers.length === 0) {
      toast.error("No draft answers to submit.");
      return;
    }
    setLoading(true);
    try {
      const answersWithMark = draftAnswers.filter(
        (answer) => answer.mark !== null && answer.mark !== undefined
      );
      const answersWithoutMark = draftAnswers.filter(
        (answer) => answer.mark === null || answer.mark === undefined
      );

      if (answersWithoutMark.length > 0) {
        toast.error(
          `${answersWithoutMark.length} draft(s) are missing marks and will be skipped!!!`
        );
      }

      if (answersWithMark.length === 0) {
        toast.error("No drafts with marks to submit!!!");
        return;
      }

      const requests = answersWithMark.map((answer) =>
        axiosClient.put("/paper-enroll/update", {
          _id: answer._id,
          status: "submitted",
          mark: answer.mark,
        })
      );

      await Promise.all(requests);

      toast.success(
        `${answersWithMark.length} draft(s) submitted successfully!`
      );
      fetchData();
      setOpenSubmitAll(false);
    } catch (err) {
      console.log("submit all error" + err);
      toast.error("An error occurred while submitting drafts.");
    } finally {
      setLoading(false);
      setOpenSubmitAll(false);
    }
  };

  return (
    <Container>
      <IconButton onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>

      <Box mb={4}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center", mb: 4 }}>
          Review Answers
        </Typography>
        
      </Box>

      <Box mb={4}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="To-Do" />
          <Tab label="Draft" />
          <Tab label="Submitted" />
        </Tabs>
      </Box>

      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchInputChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ marginLeft: "10px" }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleReset}
          style={{ marginLeft: "10px" }}
        >
          Reset
        </Button>
        {currentTab === 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitAll}
            style={{ marginLeft: "10px" }}
          >
            Submit All
          </Button>
        )}
      </Box>

      <Box>
        {isLoading ? (
          <CircularProgress />
        ) : filteredAnswers.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Paper Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Student Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Student Email</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Student Answer Time</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Student's answers</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Checked answers</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Mark</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Feedback</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Actions</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAnswers.map((answer, index) => (
                  <TableRow key={index}>
                    <TableCell>{answer.paper.title}</TableCell>
                    <TableCell>{answer.user.full_name}</TableCell>
                    <TableCell>{answer.user.email}</TableCell>
                    <TableCell>
                      {(() => {
                        const hours = Math.floor(answer.student_answer_time / 3600);
                        const minutes = Math.floor((answer.student_answer_time % 3600) / 60);
                        const seconds = answer.student_answer_time % 60;
                        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                      })()}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleDownloadAnswer(answer.student_link)
                        }
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleDownloadAnswer(answer.teacher_link)
                        }
                        disabled={!answer.teacher_link}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>{answer.mark}</TableCell>
                    <TableCell>{answer.feedback}</TableCell>
                    <TableCell>
                      {currentTab !== 2 && (
                        <Button
                          onClick={() =>
                            handleUploadFeedback(
                              answer._id,
                              answer.teacher_link,
                              answer.status
                            )
                          }
                          variant="contained"
                          color="primary"
                        >
                          {currentTab === 0 && "Submit Feedback"}
                          {currentTab === 1 && "Update Feedback"}
                        </Button>
                      )}
                      {currentTab === 1 && (
                        <Button
                          onClick={() => clickSubmit(answer._id)}
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      )}
                      {currentTab === 2 && (
                        <Button
                          onClick={() =>
                            handleRecorrection(
                              answer._id,
                              answer.mark,
                              answer.teacher_link
                            )
                          }
                          variant="contained"
                          color="primary"
                        >
                          Recorrection
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={totalAnswers}
              page={answerPagination.page}
              onPageChange={handleChangePage}
              rowsPerPage={answerPagination.limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        ) : (
          <Typography variant="body1">No answers found.</Typography>
        )}
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Submit the marks</DialogTitle>
        <DialogContent>
          <TextField
            label="Mark"
            type="number"
            fullWidth
            margin="normal"
            name="mark"
            value={formData.mark}
            onChange={handleChange}
          />
          <TextField
            label="Feedback"
            type="text"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            value={formData.feedback}
            name="feedback"
            onChange={handleChange}
          />
          <input type="file" accept="application/pdf" onChange={handleUpload} />
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <UploadButton
            onClick={() => clickUpload("draft")}
            startIcon={<UploadIcon />}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Draft
          </UploadButton>
          <UploadButton
            onClick={() => clickUpload("submitted")}
            startIcon={<UploadIcon />}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Submit
          </UploadButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openRecorrection} onClose={handleCloseRecorrection}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            If you click 'Submit', this task will be added back to the to-do list. Additionally, the previous response email will be sent to the student. Once you complete the recorrection process, another email will be sent to the student.
          </Typography>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRecorrection} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => clickRecorrection()}
            color="primary"
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSubmitAll} onClose={handleCloseSubmitAll}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            If you click submit. All the draft messages are send to the
            submitted list.
          </Typography>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubmitAll} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => clickSubmitAll()}
            color="primary"
            disabled={loading}
          >
            Submit All
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Container>
  );
};

export default SubmitAnswers;
