import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../Components/OtherStuff/firebaseConfig";

const uploadFile = async (file, path) => {
  if (!file) {
    throw new Error("No file selected.");
  }

  const fileRef = ref(storage, path);
  try {
    await uploadBytes(fileRef, file);
    const url = await getDownloadURL(fileRef);
    return url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Error uploading file. Please try again.");
  }
};

const deleteFile = async (url) => {
  if (!url) {
    throw new Error("No file URL provided.");
  }

  const fileRef = ref(storage, url);
  try {
    await deleteObject(fileRef);
  } catch (error) {
    console.error("Error deleting file:", error);
    throw new Error("Error deleting file. Please try again.");
  }
};

export { uploadFile, deleteFile };