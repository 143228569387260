import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  Grid,
  Slide,
  CssBaseline,
  Drawer,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import heroImage from "../images/hero.jpg"; // Import hero image from assets folder
import examImage from "../images/student.jpg"; // Import content image from assets folder
import logo from "../images/logo.png"; // Import logo image

// Global styles
const GlobalStyles = styled("div")(() => ({
  "*": {
  },
}));

const HeroSection = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundImage: `url(${heroImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
  width: "100%", // Ensure full width
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  color: "#fff",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)", // Overlay effect
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: "#333",
  color: "#fff",
  textAlign: "center",
  padding: "10px 0",
  position: "relative",
  bottom: 0,
  width: "100%",
}));

const HomePage = () => {
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0); // Track scroll position
  const [isScrollingUp, setIsScrollingUp] = useState(true); // Detect scroll direction
  const [prevScrollY, setPrevScrollY] = useState(0); // To compare with current scroll position
  const [transparent, setTransparent] = useState(true); // Control top bar transparency
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Function to handle scrolling and set state
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Hide the bar when scrolling down, show it when scrolling up
    if (currentScrollY > prevScrollY) {
      setIsScrollingUp(false);
    } else {
      setIsScrollingUp(true);
    }

    // Set transparency when scrolled to the top
    if (currentScrollY > 50) {
      setTransparent(false);
    } else {
      setTransparent(true);
    }

    setPrevScrollY(currentScrollY); // Update previous scroll position
    setScrollY(currentScrollY); // Update current scroll position
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the login page
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <CssBaseline /> {/* Apply global CSS reset */}
      <GlobalStyles /> {/* Apply global font styles */}
      {/* Slide the Navbar based on scroll direction */}
      <Slide
        appear={false}
        direction="down"
        in={isScrollingUp || scrollY === 0}
      >
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: transparent ? "rgba(0, 0, 0, 0)" : "#FEC619", // Transparent at the top
            transition: "background-color 1.0s ease",
            boxShadow: transparent ? "none" : "0 4px 12px rgba(0, 0, 0, 0.1)", // No shadow when transparent
            padding: 2,
            px: 8,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Logo on the left side */}
              <img
                src={logo}
                alt="Logo"
                style={{ height: "40px", marginRight: "10px" }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.8rem",
                  color: transparent ? "#fff" : "#333",
                  display: { xs: "none", md: "block" },
                }}
              >
                Agora Maths Academy
              </Typography>
            </Box>
            {/* Desktop Login Button */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: transparent ? "#fff" : "#333",
                borderRadius: "30px",
                padding: "12px 28px",
                "&:hover": {
                  backgroundColor: "#FFEB3B",
                },
                color: transparent ? "#333333" : "#fff",
                display: { xs: "none", md: "block" },
              }}
              onClick={handleLoginClick}
            >
              Login/Signup
            </Button>
            {/* Hamburger Menu Icon */}
            <IconButton
              color="inherit"
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Slide>
      {/* Drawer for mobile menu */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ width: 250 }}
      >
        <Box sx={{ width: 250, padding: 2 }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleDrawer(false)}
            aria-label="close"
            sx={{ mb: 2 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: "1.5rem", mb: 2 }}>
            Agora Maths Academy
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 2 }}
            onClick={handleLoginClick}
          >
            Login/Signup
          </Button>
        </Box>
      </Drawer>
      {/* Hero Section */}
      <HeroSection>
        <Box sx={{ zIndex: 1 }}>
          <Typography variant="h1" sx={{ fontSize: "3rem", mb: 4 }}>
            Get the highest marks for your exams.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FFC107",
              color: "#333",
              padding: "15px 35px",
              fontSize: "1.2rem",
              borderRadius: "50px",
              "&:hover": {
                backgroundColor: "#FFB300",
              },
            }}
            onClick={handleLoginClick}
          >
            Get Started
          </Button>
        </Box>
      </HeroSection>
      {/* Content Section */}
      <Container
  maxWidth={false}
  sx={{
    py: 8,
    backgroundColor: "#EEEEEE",
    width: "100%",
    paddingX: { xs: 2, sm: 4, md: 8 },
  }}
>
  <Grid container spacing={4} alignItems="center">
    <Grid item xs={12} md={6}>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <img
          src={examImage}
          alt="Exam Papers"
          style={{ width: "80%", borderRadius: "8px" }}
        />
      </Box>
    </Grid>
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          backgroundColor: "#333", // Black background
          padding: "50px", // Add padding inside the box
          borderRadius: "18px", // Rounded corners
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "2.2rem",
            fontWeight: "bold",
            mb: 2,
            color: "#fff", // White text color
          }}
        >
          Get the best exam-ready question papers.
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: "1.2rem", lineHeight: "1.6", color: "#fff" }} // White text color
        >
          All the papers are well designed with experienced paper-marking
          teachers.
        </Typography>
      </Box>
    </Grid>
  </Grid>
</Container>

      {/* Footer */}
      <Footer>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} Agora Maths Academy. All rights
          reserved.
        </Typography>
      </Footer>
    </>
  );
};

export default HomePage;
