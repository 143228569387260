import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import profileImage from "../images/avatar.jpg";
import axiosClient from "../axiosClient";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../Components/OtherStuff/firebaseConfig";
import {
  Button,
  TextField,
  IconButton,
  Container,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditUserProfile = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordResetLoading, setIsPasswordResetLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [originalUserData, setOriginalUserData] = useState({});

  const [userData, setUserData] = useState({
    _id: "",
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    address: "",
    school: "",
    grade: "",
    shy: "",
    profile_picture: null,
  });

  const [profilePreview, setProfilePreview] = useState(profileImage);

  useEffect(() => {
    setInitialLoading(true);
    axiosClient
      .get("/user/id", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setUserData(response.data.data);
          setOriginalUserData(response.data.data);
          if (response.data.data.profile_picture) {
            setProfilePreview(response.data.data.profile_picture);
          }
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
        toast.error("Error fetching user data. Please try again.");
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, [token]);

  const handleProfilePictureChange = async (event) => {
    setIsLoading(true);
    try {
      // await setUserData((prevData) => ({
      //   ...prevData,
      //   profile_picture: event.target.files[0],
      // }));

      // const display_image_link = await uploadFile(
      //   userData.profile_picture,
      //   `images/${userData.profile_picture}`
      // );

      // setProfilePreview(display_image_link);
      // toast.success("Profile picture updated successfully");
      const file = event.target.files[0];
      const display_image_link = await uploadFile(file, `images/${file.name}`);

      setProfilePreview(display_image_link);
      setUserData((prevData) => ({
        ...prevData,
        profile_picture: display_image_link,
      }));
      toast.success("Profile picture updated successfully");
    } catch (error) {
      toast.error("Error updating profile picture. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFile = async (file, path) => {
    if (file) {
      const fileRef = ref(storage, path);
      try {
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        return url;
      } catch (error) {
        console.error("Error uploading file:", error);
        //throw new Error("Error uploading file. Please try again.");
        toast.error("Error uploading file. Please try again.");
      }
    } else {
      //throw new Error("No file selected.");
      toast.error("No file selected.");
    }
  };

  const handleChange = (e) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const hasChanges = Object.keys(userData).some(
      (key) =>
        userData[key] !== originalUserData[key] &&
        key !== "_id" &&
        key !== "email"
    );

    if (!hasChanges) {
      toast.info("No changes detected.");
      return;
    }
    setIsLoading(true);
    const updatedUserData = {
      _id: userData._id,
      phone: userData.phone,
      first_name: userData.first_name,
      last_name: userData.last_name,
      address: userData.address,
      school: userData.school,
      shy: userData.shy,
      grade: userData.grade,
      profile_picture: profilePreview || userData.profile_picture,
    };
    try {
      const response = await axiosClient.put("/user/update", updatedUserData);
      if (response.data.status) {
        toast.success("Profile updated successfully");
        setOriginalUserData(updatedUserData);
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("Error updating profile data! Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordResetClick = (e) => {
    e.preventDefault();
    setIsPasswordResetLoading(true);
    const payload = {
      email: userData.email,
    };
    axiosClient
      .post("/user/forget-password", payload)
      .then((response) => {
        if (response.data.status) {
          toast.success("Password reset email sent. Redirecting...");
          setTimeout(() => {
            navigate("/enterresetcode", { state: { email: payload.email } });
        }, 2000);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error sending password reset email. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (initialLoading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <IconButton onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>

      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box display="flex" alignItems="center" mb={2}>
          <Box>
            <Typography component="h1" variant="h5" align="center">
              Edit Profile
            </Typography>
            <Box alignItems="center" mb={2} sx={{ position: 'relative', width: '100px', height: '100px' }}>
              <label htmlFor="profileImageInput" style={{ width: '100%', height: '100%' }}>
                <img
                  alt="Profile"
                  src={profilePreview}
                  style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    borderRadius: '50%', // Make the image circle
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Light gray overlay
                    borderRadius: '50%', // Make the overlay circle
                    opacity: 0, // Initially invisible
                    transition: 'opacity 0.3s',
                    cursor: 'pointer', // Change cursor to pointer on hover
                    '&:hover': {
                      opacity: 1, // Visible on hover
                    },
                  }}
                />
              </label>
              <input
                type="file"
                id="profileImageInput"
                name="profile_picture"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleProfilePictureChange}
              />
            </Box>
            <form onSubmit={handleSave}>
              <TextField
                label="Email"
                value={userData.email}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Phone"
                name="phone"
                value={userData.phone}
                fullWidth
                margin="normal"
                onChange={handleChange}
              />
              <TextField
                label="First Name"
                name="first_name"
                value={userData.first_name}
                fullWidth
                margin="normal"
                onChange={handleChange}
              />
              <TextField
                label="Last Name"
                name="last_name"
                value={userData.last_name}
                fullWidth
                margin="normal"
                onChange={handleChange}
              />
              <TextField
                label="Address"
                name="address"
                value={userData.address}
                fullWidth
                margin="normal"
                onChange={handleChange}
              />
              <TextField
                label="School"
                name="school"
                value={userData.school}
                fullWidth
                margin="normal"
                onChange={handleChange}
              />
              <TextField
                label="Grade"
                name="grade"
                value={userData.grade}
                fullWidth
                margin="normal"
                onChange={handleChange}
              />
              <TextField
                select
                label="A/L Attempt"
                name="shy"
                value={userData.shy}
                fullWidth
                margin="normal"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="1">1<sup>st</sup></option>
                <option value="2">2<sup>nd</sup></option>
                <option value="3">3<sup>rd</sup></option>
              </TextField>
              <Box mt={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Save"}
                </Button>
              </Box>

              {/* <Box mt={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handlePasswordResetClick}
                  disabled={isPasswordResetLoading}
                >
                  {isPasswordResetLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Change Password"
                  )}
                </Button>
              </Box> */}
            </form>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default EditUserProfile;
