import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

const StateContext = createContext({
    user: null,
    token: null,
    setUser: () => {},
    setToken: () => {}
});

export const ContextProvider = ({children}) => {
    const [user, _setUserID] = useState(localStorage.getItem('USER_ID'));
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
  //  const [user__id,_setUserId] = useState(localStorage.getItem('USER_ID'))
 //   const [token, _setToken] = useState(1111);

    const setToken = (token) => {
        
        // console.log(token)
        _setToken(token)
        if(token){
            // localStorage.removeItem('ACCESS_TOKEN');
            localStorage.setItem('ACCESS_TOKEN',token);
        }
        else{
            localStorage.removeItem('ACCESS_TOKEN');
        }
    }

    const setUser = (user) =>{
        _setUserID(user)
        if(user){
            // localStorage.removeItem('ACCESS_TOKEN');
            localStorage.setItem('USER_ID',user);
        }
        else{
            localStorage.removeItem('USER_ID');
        }

    }




    return (
        <StateContext.Provider value={{
            user,
            token,
            setUser,
            setToken
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)