import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Container,
  CssBaseline,
  Box,
  CircularProgress,
} from "@mui/material";
import axiosClient from "../axiosClient";
import logo from "../images/logo.jpg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ChangePassword() {
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const _id = location.state?._id;
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match. Please try again.");
      toast.error("Passwords do not match. Please try again.");
      setIsLoading(false);
      return;
    }

    const payload = {
      _id,
      password: newPassword,
    };

    axiosClient
      .put("/user/update", payload)
      .then((response) => {
        if (response.data.status) {
          toast.success(
            "Password changed successfully. Redirecting to login..."
          );
          setTimeout(() => navigate("/login"), 2000);
        } else if (response.data.status === false) {
          toast.error(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" disableGutters sx={{ width: '100vw', overflowX: 'hidden' }}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            flex: { xs: 1, sm: 0.3 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              maxWidth: '80%',
              height: 'auto',
            }}
          />
          <Typography variant="h4" sx={{ mt: 2, mb: 2, fontWeight: 'bold', color: '#000000' }}>
            Agora Academy
          </Typography>
        </Box>
        <Box
          sx={{
            flex: { xs: 1, sm: 0.7 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 3,
          }}
        >
          <Box className="login-signup-form animated fadeinDown" sx={{ width: '100%', maxWidth: 400 }}>
            <Typography component="h1" variant="h5" align="center">
              Change Password
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                inputRef={newPasswordRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                type="password"
                autoFocus
              />
              <TextField
                inputRef={confirmPasswordRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type="password"
              />
              {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
                sx={{
                  backgroundColor: '#FEC619',
                  color: '#333333',
                  '&:hover': {
                    backgroundColor: '#e6b000',
                  },
                  mt: 2,
                  mb: 2,
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Change Password"}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </Container>
  );
}
