import React, { useState, useEffect, useRef } from "react";

import logoImage from "../images/logo.jpg";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  ChevronLeft,
  ChevronRight,
  Logout,
  Settings,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import axiosClient from "../axiosClient";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profileImage from "../images/avatar.jpg";

const ProfileContainer = styled(Paper)(({ theme, collapsed, open }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: collapsed ? "center" : "flex-start",
  padding: "20px",
  backgroundColor: collapsed ? "#E3E3E3" : "#f9f9f9",
  borderRadius: "10px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: collapsed ? "40px" : "400px",
  minHeight: "100vh",
  position: "relative",
  justifyContent: "flex-start",
  overflow: "hidden",
  transition: "width 0.5s ease-in-out",
  overflowY: "auto",
}));

const LogoContainer = styled(Box)(({ theme, collapsed }) => ({
  display: "flex",
  justifyContent: collapsed ? "center" : "flex-start",
  alignItems: "center",
  width: "100%",
  padding: "1px",
  boxSizing: "border-box",
  position: "relative",
}));

const ProfileImgContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: "20px",
});

const ProfileDetails = styled(Box)(() => ({
  textAlign: "center",
  width: "100%",
  "& .profile-subjects, & .profile-notifications": {
    marginTop: "20px",
    textAlign: "left",
    "& h6": {
      color: "#757575", // Updated color for the "My Subjects" heading
      marginBottom: "10px",
      fontSize: "1rem", // Adjusted size for clarity
    },
    "& ul": {
      listStyleType: "none",
      padding: 0,
      margin: 0, // Removes any unwanted margin/padding
    },
  },
}));

const CustomListItem = styled(ListItem)(() => ({
  backgroundColor: "#F9C032",
  width: "100%", // Full width for better alignment
  color: "#4A4A4A", // Updated darker text color for better readability
  borderRadius: "80px", // Rounded corners as per Google's style
  padding: "8px 16px", // Standard vertical and horizontal padding
  fontSize: "14px", // Standard font size for list items
  cursor: "pointer",
  transition: "background-color 0.25s", // Smooth transitions for hover effects
  textAlign: "left",
  "&:hover, &:focus, &:active": {
    backgroundColor: "#FCD165", // Updated slightly lighter background on hover
  },
  "&:active": {
    backgroundColor: "#F9C032", // Updated darker background when pressed
  },
}));

const CustomListItem2 = styled(ListItem)(() => ({
  width: "100%", // Full width for better alignment
  color: "#333333", // Google's dark mode text color for readability
  borderRadius: "80px", // Rounded corners as per Google's style
  padding: "8px 16px", // Standard vertical and horizontal padding
  fontSize: "14px", // Standard font size for list items
  cursor: "pointer",
  transition: "background-color 0.25s", // Smooth transitions for hover effects
  textAlign: "left",
  "&:hover, &:focus, &:active": {
    backgroundColor: "#FCD165", // Updated slightly lighter background on hover
  },
  "&:active": {
    backgroundColor: "#F9C032", // Updated darker background when pressed
  },
}));

const SubtopicListItem = styled(ListItem)(() => ({
  padding: "4px 12px",
  fontSize: "12px",
  color: "#555",
  marginLeft: "40px", // Indent subtopics
  cursor: "pointer", // Indicate clickability
  borderBottom: "2px solid #F3F3F3",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#f0f0f0", // Light gray for hover effect
  },
}));

const SettingsContainer = styled(Box)(({ collapsed }) => ({
  position: "absolute",
  bottom: "10px",
  left: collapsed ? "50%" : "10px",
  transform: collapsed ? "translateX(-50%)" : "none",
  width: "100%",
  textAlign: "left",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
}));

const PopupContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#CAFFF6",
  padding: "20px",
  borderRadius: "10px",
  border: "2px solid #FEC619",
  position: "absolute",
  bottom: "60px",
  left: "10px",
  zIndex: 1300,
  boxShadow: "0 5px 5px rgba(0, 113, 227, 0.5)",
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    left: 0,
    bottom: 0,
    borderRadius: "10px 10px 0 0",
  },
}));

const CreditBalance = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
});

const TopUpButton = styled(Button)({
  borderRadius: "20px",
});

const CenteredListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "8px 12px",
  fontSize: "14px",
  cursor: "pointer",
  marginBottom: "5px",
  borderRadius: "10px",
  transition: "background-color 0.3s",
  textAlign: "center",
  color: "#333333",
  border: "1px solid #CAFFF6",
  "&:hover": {
    backgroundColor: "#CAFFF6",
    borderColor: "#91C1B8",
  },
}));

const ProfileInfo = ({ subid, user, onLogout }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [showLogout, setShowLogout] = useState(false);
  const popupRef = useRef(null);
  const [creditBalance, setCreditBalance] = useState(0);
  const [userData, setUserData] = useState({});
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get("/user/id", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          console.log("User data", response.data);
          setUserData(response.data.data);
          setCreditBalance(response.data.data.price);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
      });

    axiosClient
      .get("/subject?limit=3&page=1")
      .then((response) => {
        if (response.data.status) {
          const fetchedSubjects = response.data.data.subjects || [];
          setSubjects(fetchedSubjects);
          if (fetchedSubjects.length > 0) {
            handleSubjectClick(fetchedSubjects[1]);
          }
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the subjects!", error);
      });

    setIsLoading(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   axiosClient
  //     .get("/user/credit-balance") // Adjust the endpoint as necessary
  //     .then((response) => {
  //       setCreditBalance(response.data.balance);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error fetching the credit balance!", error);
  //     });
  // }, []);

  useEffect(() => {
    console.log("selected subject: " + selectedSubject);
  }, [selectedSubject]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handlePaperSubmitClick = () => {
    navigate(`/insertpaper`);
  };

  const editProfile = () => {
    navigate("/editprofile");
  };

  const handleCreateFolderClick = () => {
    navigate(`/createfolder`);
  };

  const handleSubmitAnswersClick = () => {
    navigate(`/submitanswers`);
  };

  const handlePaymentManagementClick = () => {
    navigate(`/paymentmanagement`);
  };

  const toggleLogout = () => {
    setShowLogout(!showLogout);
  };

  const handleCreditTopUpClick = () => {
    navigate("/topupopup"); // Navigate to PopupMessagePage
  };
  const handleMyEnroleClick = () => {
    navigate("/myenrole"); // Navigate to PopupMessagePage
  };

  const handleSubjectClick = (subject) => {
    subid(subject._id);
    setSelectedSubject(subject._id);
  };

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
      <ProfileContainer
        collapsed={collapsed}
        open={open}
        sx={{ width: collapsed ? "40px" : "85%", backgroundColor: "#EBFFFC" }}
      >
        <LogoContainer
          collapsed={collapsed}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          {!collapsed && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt="Logo"
                src={logoImage}
                sx={{ width: 48, height: 48, bgcolor: "secondary.main" }}
              />
              <Typography variant="h6" sx={{ marginLeft: 2 }}>
                Agora Academy
              </Typography>
            </Box>
          )}

          <IconButton
            onClick={toggleCollapse}
            aria-label={collapsed ? "expand" : "collapse"}
          >
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </LogoContainer>
        {!collapsed && (
          <>
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                sx={{ bgcolor: "background.default" }}
              >
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <>
                <ProfileDetails sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Box
                      className="profile-subjects"
                      sx={{ marginBottom: "30px" }}
                    >
                      <CustomListItem>
                        <ListItemText primary="My Subjects" />
                      </CustomListItem>
                      <List>
                        {subjects.length > 0 ? (
                          subjects.map((subject) => (
                            <SubtopicListItem
                              key={subject._id}
                              onClick={() => handleSubjectClick(subject)}
                              disabled={
                                subject._id !== "6714ab08c02346c9e06e01b1"
                              }
                            >
                              <ListItemText primary={subject.name} />
                            </SubtopicListItem>
                          ))
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{ color: "#757575", marginLeft: "20px" }}
                          >
                            No subjects found.
                          </Typography>
                        )}
                      </List>
                    </Box>

                    {userData.role !== "Student" && (
                      <Box
                        className="profile-management"
                        sx={{ borderRadius: "10px", overflow: "hidden" }}
                      >
                        <List>
                          <CustomListItem2 onClick={handlePaperSubmitClick}>
                            <IconButton>
                              <ArticleIcon />
                            </IconButton>
                            <ListItemText primary="Paper Management" />
                          </CustomListItem2>
                          <CustomListItem2 onClick={handleCreateFolderClick}>
                            <IconButton>
                              <CreateNewFolderIcon />
                            </IconButton>

                            <ListItemText primary="Folder Management" />
                          </CustomListItem2>
                          <CustomListItem2 onClick={handleSubmitAnswersClick}>
                            <IconButton>
                              <QuestionAnswerIcon />
                            </IconButton>
                            <ListItemText primary="Review answers" />
                          </CustomListItem2>
                          <CustomListItem2
                            onClick={handlePaymentManagementClick}
                          >
                            <IconButton>
                              <AccountBalanceIcon />
                            </IconButton>
                            <ListItemText primary="Payment Management" />
                          </CustomListItem2>
                        </List>
                      </Box>
                    )}
                  </Box>
                </ProfileDetails>
              </>
            )}
          </>
        )}

        <SettingsContainer collapsed={collapsed}>
          {!collapsed && (
            <>
              <Box>
                <Box
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "#FCD165",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    width: "80%",
                    border: "1px solid #E0E0E0",
                    "&:hover": {
                      backgroundColor: "rgba(252, 209, 101, 0.8)",
                      transition: "background-color 0.3s ease-in-out",
                      cursor: "pointer",
                    },
                  }}
                  onClick={toggleLogout}
                >
                  <ProfileImgContainer
                    sx={{
                      marginBottom: { xs: 2, sm: 0 },
                    }}
                  >
                    <Avatar
                      alt="Profile"
                      src={userData.profile_picture || profileImage}
                      sx={{
                        width: 64,
                        height: 64,
                        m: 0,
                        p: 0,
                      }}
                    />
                  </ProfileImgContainer>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontWeight: "bold", userSelect: "none" }}>
                      {userData.first_name} {userData.last_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ userSelect: "none" }}
                    >
                      {userData.role}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {showLogout && (
                <PopupContainer ref={popupRef}>
                  <CreditBalance sx={{ textAlign: "center" }}>
                    <Typography variant="h6" component="p">
                      🪙 {creditBalance} LKR
                    </Typography>
                    <TopUpButton
                      onClick={handleCreditTopUpClick}
                      variant="contained"
                      color="primary"
                    >
                      Top Up
                    </TopUpButton>
                  </CreditBalance>

                  <List
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <CenteredListItem onClick={handleMyEnroleClick}>
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        <ArticleIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="My Enrollments"
                        sx={{ textAlign: "left" }}
                      />
                    </CenteredListItem>

                    <CenteredListItem onClick={editProfile}>
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        <ManageAccountsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Profile Settings"
                        sx={{ textAlign: "left" }}
                      />
                    </CenteredListItem>
                    <CenteredListItem onClick={onLogout}>
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        <Logout />
                      </ListItemIcon>
                      <ListItemText
                        primary="LogOut"
                        sx={{ textAlign: "left" }}
                      />
                    </CenteredListItem>
                  </List>
                </PopupContainer>
              )}
            </>
          )}
        </SettingsContainer>
      </ProfileContainer>
      <ToastContainer />
    </Box>
  );
};

export default ProfileInfo;
