import { createBrowserRouter } from 'react-router-dom';
import Login from './views/login.jsx';
import Register from './views/register.jsx';
import DefaultLayout from './ComponentsAlpha/DefaultLayout.jsx';
import GuestLayout from './ComponentsAlpha/GuestLayout.jsx';
import InsertPaper from './views/InsertPaper.jsx';
import CreateFolder from './views/CreateFolder.jsx';
import SubmitAnswers from './views/SubmitAnswers.jsx';
import PaymentManagement from './views/PaymentManagement.jsx';
import LayerZero from './views/LayerZero.jsx';
import StudentViewOfPaper from './views/StudentViewOfPaper.jsx';
import AnswerScriptStudentView from './views/AnswerScriptStudentView.jsx';
import PaperView from './views/PaperView.jsx';
import ForgetPassword from './views/ForgetPassword.jsx';
import EnterResetCode from './views/EnterResetCode.jsx';
import ChangePassword from './views/ChangePassword.jsx';
import PopupMessagePage from './views/PopupMessagePage.jsx';
import EnterEmail from './views/EnterEmail.jsx'
import EditUserProfile from './views/EditUserProfile.jsx';
import MyEnrole from './views/MyEnrole.jsx';
import HomePage from './views/HomePage.jsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: '/',
        element: <LayerZero />,
      },
      {
        path: '/insertpaper',
        element: <InsertPaper />,
      },
      {
        path: '/createfolder',
        element: <CreateFolder />,
      },
      {
        path: '/submitanswers',
        element: <SubmitAnswers />,
      },
      {
        path: '/paymentmanagement',
        element: <PaymentManagement />,
      },
      {
        path: '/viewpaper',
        element: <StudentViewOfPaper />
      },
      {
        path: '/viewanswer/:paperid',
        element: <AnswerScriptStudentView />
      },
      {
        path: '/pdfview/:paperid',
        element: <PaperView />,
      },      
      {
        path: '/topupopup',
        element: <PopupMessagePage />
      },
      {
        path: '/editprofile',
        element: <EditUserProfile />
      },
      {
        path: '/myenrole',
        element: <MyEnrole/>
      },
    ]
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/home',
        element: <HomePage/>,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <EnterEmail/>,
      },
      {
        path: '/createaccount',
        element: <Register/>,
      },
      {
        path: '/forgetpassword',
        element: <ForgetPassword />,
      },
      {
        path: '/enterresetcode',
        element: <EnterResetCode/>,
      },
      {
        path: '/changepassword',
        element: <ChangePassword/>,
      }
    ]
  },
]);

export default router;
