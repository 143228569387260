import React, { useState, useEffect } from "react";
import { storage } from "../Components/OtherStuff/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axiosClient from "../axiosClient";
import {
  Button,
  TextField,
  IconButton,
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UploadButton = styled(Button)({
  marginRight: 16,
});

const PreviewContainer = styled(Paper)({
  display: "flex",
  alignItems: "center",
  padding: "8px 12px",
  marginLeft: 16,
  backgroundColor: "#f9f9f9",
});

const TabPanel = ({ children, value, index }) => {
  return value === index ? <Box>{children}</Box> : null;
};

const InsertPaper = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    duration: dayjs("00:00", "HH:mm"),
    publish_date: dayjs().format("YYYY-MM-DD"),
    display_image: null,
    video_link: "",
    description: "",
    file: null,
    markingSchemeFile: null,
    price: 0,
  });

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryDetail, setSelectedCategoryDetail] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("");
  const userId = localStorage.getItem("USER_ID");
  const [hasFolder, setHasFolder] = useState(false);
  const [papers, setPapers] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedPaperId, setSelectedPaperId] = useState("");
  const [loading, setLoading] = useState(false);
  const [finalLoadingSubjects, setFinalLoadingSubjects] = useState(false);
  const [finalLoadingCategories, setFinalLoadingCategories] = useState(false);
  const [finalLoadingFolders, setFinalLoadingFolders] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [totalPapers, setTotalPapers] = useState(0);
  const [paperPagination, setPaperPagination] = useState({
    page: 0,
    limit: 25,
  });

  useEffect(() => {
    setFinalLoadingSubjects(true);
    axiosClient
      .get("/subject?limit=10&page=1")
      .then((response) => {
        if (response.data.status) {
          const subjects = response.data.data.subjects || [];
          setSubjects(subjects);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Error fetching subjects", error);
        toast.error("Failed to load subjects. Please refresh the page.");
      })
      .finally(() => {
        setFinalLoadingSubjects(false);
      });
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      setFinalLoadingCategories(true);
      axiosClient
        .get(`/categories?limit=20&page=1&subject_id=${selectedSubject}`)
        .then((response) => {
          if (response.data.status) {
            const categories = response.data.data.categories || [];
            setCategories(categories);
          } else if (!response.data.status) {
            toast.error(response.data.msg);
          }
        })
        .catch((error) => {
          console.error("Error fetching categories", error);
        })
        .finally(() => {
          setFinalLoadingCategories(false);
        });
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedCategory) {
      const currentCategory = categories.find(
        (cat) => cat._id === selectedCategory
      );
      setSelectedCategoryDetail(currentCategory);
      setFinalLoadingFolders(true);
      if (currentCategory.has_folder) {
        setHasFolder(currentCategory.has_folder);
        axiosClient
          .get(`/folders?limit=100&page=1&category_id=${selectedCategory}`)
          .then((response) => {
            if (response.data.status) {
              const folders = response.data.data.folders || [];
              setFolders(folders);
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((error) => {
            console.error("Error fetching folders", error);
          })
          .finally(() => {
            setFinalLoadingFolders(false);
          });
      }
    } else {
      setSelectedCategoryDetail(null);
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    if (tabValue === 1) {
      fetchPapers();
    }
  }, [tabValue, paperPagination]);

  const fetchPapers = async () => {
    axiosClient
      .get(
        `/papers?limit=${paperPagination.limit}&page=${
          paperPagination.page + 1
        }&user_id=${userId}`
      )
      .then((response) => {
        if (response.data.status) {
          const papers = response.data.data.papers || [];
          setPapers(papers);
          setTotalPapers(response.data.data.recordsTotal);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Error fetching papers", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPaperPagination({
      ...paperPagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPaperPagination({
      ...paperPagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event) => {
    setFormData((prevData) => ({ ...prevData, file: event.target.files[0] }));
  };

  const handleRemoveFile = () => {
    setFormData((prevData) => ({ ...prevData, file: null }));
  };

  const handleMarkingSchemeChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      markingSchemeFile: event.target.files[0],
    }));
  };

  const handleRemoveMarkingScheme = () => {
    setFormData((prevData) => ({ ...prevData, markingSchemeFile: null }));
  };

  const handleImageChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      display_image: event.target.files[0],
    }));
  };

  const handleRemoveImage = () => {
    setFormData((prevData) => ({ ...prevData, display_image: null }));
  };

  const uploadFile = async (file, path) => {
    if (file) {
      const fileRef = ref(storage, path);
      try {
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        return url;
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file. Please try again.");
        throw new Error("Error uploading file. Please try again.");
      }
    } else {
      toast.error("No file selected.");
      throw new Error("No file selected.");
    }
  };

  const handleSubmit = async () => {
    setUploading(true);
    setError("");
    setFieldErrors({});

    const errors = {};
    if (!formData.file) errors.file = true;
    if (
      !selectedFolder &&
      selectedCategoryDetail &&
      selectedCategoryDetail.has_folder
    )
      errors.folder = true;
    if (!formData.title) errors.title = true;
    if (!formData.duration || formData.duration.isSame(dayjs("00:00", "HH:mm")))
      errors.duration = true;
    if (!formData.display_image) errors.display_image = true;
    if (!formData.description) errors.description = true;
    if (!selectedSubject) errors.subject = true;
    if (!selectedCategory) errors.category = true;
    if (!formData.markingSchemeFile && !formData.video_link) {
      errors.markingSchemeFile = true;
      errors.video_link = true;
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      toast.error("Please fill in all required fields!!!");
      setUploading(false);
      return;
    }

    try {
      const paper_link = await uploadFile(
        formData.file,
        `pdfs/${formData.file.name}`
      );
      let answer_link = null;
      if (formData.markingSchemeFile) {
        answer_link = await uploadFile(
          formData.markingSchemeFile,
          `marking_schemes/${formData.markingSchemeFile.name}`
        );
      }
      const display_image_link = await uploadFile(
        formData.display_image,
        `images/${formData.display_image.name}`
      );

      const durationInHours = formData.duration.hour();
      const durationInMinutes = formData.duration.minute();
      const formattedDuration = `${durationInHours} hr ${durationInMinutes} min`;

      const dataToSubmit = {
        title: formData.title,
        subject_id: selectedSubject,
        category_id: selectedCategory,
        teacher_id: userId,
        duration: formattedDuration,
        publish_date: formData.publish_date,
        paper_link,
        display_image_link,
        description: formData.description,
        price: Number(formData.price),
      };

      if (answer_link) {
        dataToSubmit.answer_link = answer_link;
      }
      if (formData.video_link) {
        dataToSubmit.video_link = formData.video_link;
      }

      // Only include folder_id if a folder is selected
      if (hasFolder && selectedFolder) {
        dataToSubmit.folder_id = selectedFolder;
      }

      const response = await axiosClient.post("/papers/create", dataToSubmit);

      if (response.data.status) {
        toast.success("Paper added successfully!");
        setFormData({
          title: "",
          duration: dayjs("00:00", "HH:mm"),
          publish_date: dayjs().format("YYYY-MM-DD"),
          display_image: null,
          video_link: "",
          description: "",
          file: null,
          markingSchemeFile: null,
          price: 0,
        });
        setSelectedSubject("");
        setSelectedCategory("");
        setSelectedFolder("");
        setHasFolder(false);
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("Failed to add paper: " + error);
    } finally {
      setUploading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDelete = (id) => {
    setSelectedPaperId(id);
    setOpenDelete(true);
  };

  const clickDelete = async () => {
    setLoading(true);
    try {
      if (selectedPaperId) {
        await axiosClient
          .delete(`/papers/delete/${selectedPaperId}`)
          .then((response) => {
            if (response.data.status) {
              toast.success("Paper deleted successfully!");
              fetchPapers();
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            console.log("Error deleting paper: " + err);
          });
      }
    } catch (err) {
      console.log("Error deleting paper: " + err);
    } finally {
      setLoading(false);
      setOpenDelete(false);
    }
  };

  const handleClose = () => {
    setSelectedPaperId("");
    setOpenDelete(false);
  };

  const handleView = (paperId) => {
    navigate(`/pdfview/${paperId}`);
    toast.info("Loading paper view...");
  };

  return (
    <Container>
      <IconButton onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>

      <Box mb={4}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", mb: 4 }}
        >
          Paper Management
        </Typography>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mb: 3,
          }}
        >
          <Tab label="Add a Paper" sx={{ flex: 1 }} />
          <Tab label="View Papers" sx={{ flex: 1 }} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Typography
            variant="body1"
            color="textSecondary"
            gutterBottom
            sx={{ mb: 2 }}
          >
            Please upload your paper as a PDF file and ensure to provide either
            a marking scheme or a valid YouTube link for any related discussion
            or presentation. This will help us properly categorize and make your
            submission accessible to others.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
            Add a paper
          </Typography>
          {error && (
            <Typography
              variant="body1"
              color="error"
              gutterBottom
              sx={{ mb: 2 }}
            >
              {error}
            </Typography>
          )}

          <FormControl fullWidth margin="normal" sx={{ mb: 3 }}>
            <InputLabel shrink htmlFor="select-subject" sx={{ background: '#fff', padding: '0 4px' }}>Select Subject</InputLabel>
            <Select
              id="select-subject"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              displayEmpty
              disabled={finalLoadingSubjects}
              error={fieldErrors.subject}
            >
              {subjects.map((subject) => (
                <MenuItem key={subject._id} value={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            margin="normal"
            disabled={!selectedSubject || finalLoadingCategories}
            sx={{ mb: 2 }}
          >
            <InputLabel shrink htmlFor="select-category" sx={{ background: '#fff', padding: '0 4px' }}>Select Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              error={fieldErrors.category}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            margin="normal"
            sx={{ mb: 2 }}
            disabled={!selectedCategory || finalLoadingFolders}
          >
            <InputLabel shrink htmlFor="select-folder" sx={{ background: '#fff', padding: '0 4px' }}>Select Folder</InputLabel>
            <Select
              value={selectedFolder}
              onChange={(e) => setSelectedFolder(e.target.value)}
              displayEmpty
              error={fieldErrors.folder}
            >
              {folders.map((folder) => (
                <MenuItem key={folder._id} value={folder._id}>
                  {folder.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" alignItems="center" mb={2}>
              {!formData.file && (
                <UploadButton
                  variant="outlined"
                  component="label"
                  startIcon={<AddIcon />}
                  error={fieldErrors.file}
                  style={{ borderColor: fieldErrors.file ? "red" : undefined }}
                >
                  Upload File
                  <input
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={handleFileChange}
                  />
                </UploadButton>
              )}
              {formData.file && (
                <PreviewContainer>
                  <Typography variant="body1">{formData.file.name}</Typography>
                  <IconButton onClick={handleRemoveFile}>
                    <CloseIcon />
                  </IconButton>
                </PreviewContainer>
              )}
            </Box>

            <Box display="flex" alignItems="center" mb={2}>
              {!formData.markingSchemeFile && (
                <UploadButton
                  variant="outlined"
                  component="label"
                  startIcon={<AddIcon />}
                  error={
                    fieldErrors.markingSchemeFile && fieldErrors.video_link
                  }
                  style={{
                    borderColor:
                      fieldErrors.markingSchemeFile && fieldErrors.video_link
                        ? "red"
                        : undefined,
                  }}
                >
                  Upload Marking Scheme
                  <input
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={handleMarkingSchemeChange}
                  />
                </UploadButton>
              )}
              {formData.markingSchemeFile && (
                <PreviewContainer>
                  <Typography variant="body1">
                    {formData.markingSchemeFile.name}
                  </Typography>
                  <IconButton onClick={handleRemoveMarkingScheme}>
                    <CloseIcon />
                  </IconButton>
                </PreviewContainer>
              )}
            </Box>

            <Box display="flex" alignItems="center" mb={2}>
              {!formData.display_image && (
                <UploadButton
                  variant="outlined"
                  component="label"
                  startIcon={<AddIcon />}
                  error={fieldErrors.display_image}
                  style={{
                    borderColor: fieldErrors.display_image ? "red" : undefined,
                  }}
                >
                  Upload Display Image
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleImageChange}
                  />
                </UploadButton>
              )}
              {formData.display_image && (
                <PreviewContainer>
                  <Typography variant="body1">
                    {formData.display_image.name}
                  </Typography>
                  <IconButton onClick={handleRemoveImage}>
                    <CloseIcon />
                  </IconButton>
                </PreviewContainer>
              )}
            </Box>
          </Box>

          <TextField
            fullWidth
            margin="normal"
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            error={fieldErrors.title}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Duration (Hours and Minutes)"
              value={formData.duration}
              onChange={(newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  duration: newValue,
                }));
              }}
              views={["hours", "minutes"]}
              format="HH:mm"
              ampm={false}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: "normal",
                  error: fieldErrors.duration,
                },
              }}
              error={fieldErrors.duration}
            />
          </LocalizationProvider>

          <TextField
            fullWidth
            margin="normal"
            label="Publish Date"
            name="publish_date"
            type="date"
            value={formData.publish_date}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Video Link"
            name="video_link"
            value={formData.video_link}
            onChange={handleChange}
            error={fieldErrors.video_link && fieldErrors.markingSchemeFile}
            helperText={
              fieldErrors.video_link && fieldErrors.markingSchemeFile
                ? "Either Video Link or Marking Scheme is required"
                : ""
            }
          />

          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            multiline
            rows={4}
            value={formData.description}
            onChange={handleChange}
            error={fieldErrors.description}
          />

          <TextField
            fullWidth
            margin="normal"
            type="number"
            label="Price"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />

          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={uploading}
            >
              {uploading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box mt={4} mb={2}>
            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
              View Papers
            </Typography>
            {papers.length > 0 ? (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Subject</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Folder</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {papers.map((paper) => (
                        <TableRow key={paper._id}>
                          <TableCell >{paper.title}</TableCell>
                          <TableCell>{paper.subject.name}</TableCell>
                          <TableCell>{paper.category.name}</TableCell>
                          <TableCell>
                            {paper.folder ? paper.folder.name : "N/A"}
                          </TableCell>
                          <TableCell>{paper.duration}</TableCell>
                          <TableCell>{paper.price}</TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleView(paper._id)}
                                size="small"
                                style={{ marginBottom: "8px" }} // Adds space between buttons
                              >
                                View
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleDelete(paper._id)}
                                size="small"
                              >
                                Delete
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={totalPapers}
                  page={paperPagination.page}
                  onPageChange={handleChangePage}
                  rowsPerPage={paperPagination.limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </>
            ) : (
              <Typography variant="body1">No papers found.</Typography>
            )}
          </Box>
        </TabPanel>
      </Box>

      <Dialog open={openDelete} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            If you click submit, this paper will be deleted from the system.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={clickDelete} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Container>
  );
};

export default InsertPaper;
