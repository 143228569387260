import React, { useState, useEffect } from "react";
import axiosClient from "../axiosClient";
import {
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentManagement = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [paymentData, setPaymentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const statuses = ["to-do-payment", "approved", "not-approved"];
  const [error, setError] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openNotApprove, setOpenNotApprove] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState("");
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [largeImage, setLargeImage] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isNotApproving, setIsNotApproving] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [totalPayments, setTotalPayments] = useState(0);
  const [paymentPagination, setPaymentPagination] = useState({
    page: 0,
    limit: 25
  });

  useEffect(() => {
    fetchData();
  }, [searchQuery, currentTab, paymentPagination]);

  const fetchData = () => {
    setIsLoading(true);
    const search = searchQuery ? `&search=${searchQuery}` : "";
    axiosClient
      .get(`/payments?limit=${paymentPagination.limit}&page=${paymentPagination.page + 1}${search}`)
      .then((response) => {
        if (response.data.status) {
          console.log("Payments response: ", response);
          const enrollPapers = response.data.data.payments || [];
          console.log("Payments: ", enrollPapers);
          setPaymentData(enrollPapers);
          setTotalPayments(response.data.data.recordsTotal);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Error fetching payments data", error);
        toast.error("Failed to load payments. Please try again.");
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangePage = (event,newPage) => {
    setPaymentPagination({
      ...paymentPagination,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPaymentPagination({
      ...paymentPagination,
      limit: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const filteredPayments = paymentData.filter(
    (payment) => payment.status === statuses[currentTab]
  );

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    setIsSearching(true);
    setSearchQuery(searchTerm);
    setIsSearching(false);
  };

  const handleReset = () => {
    setSearchTerm("");
    setSearchQuery("");
  };

  const handleClickImage = (imageLink) => {
    setOpenImage(true);
    setLargeImage(imageLink);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
    setLargeImage("");
  };

  const handleApprove = async (id) => {
    setSelectedPaymentId(id);
    setOpenApprove(true);
    setPriceError(false);
  };

  const clickApprove = async () => {
    if (price <= 0) {
      setPriceError(true);
      toast.error("Price is required!!!");
      return;
    }
    setIsApproving(true);
    try {
      const recorreectionItem = {
        _id: selectedPaymentId,
        price: price,
        transfer_date: new Date(),
        status: "approved",
      };

      if (selectedPaymentId) {
        await axiosClient
          .put("/payments/update", recorreectionItem)
          .then((response) => {
            if (response.data.status) {
              toast.success("Following payment set to approved successfully!");
              fetchData();
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            toast.error("An error occurred. Please try again.");
          });
      }
      setOpenApprove(false);
      setPrice(0);
      setSelectedPaymentId("");
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsApproving(false);
    }
  };

  const handleNotApprove = async (id) => {
    setSelectedPaymentId(id);
    setOpenNotApprove(true);
  };

  const clickNotApprove = async () => {
    setIsNotApproving(true);
    try {
      const recorreectionItem = {
        _id: selectedPaymentId,
        status: "Declined",
      };

      if (selectedPaymentId) {
        await axiosClient
          .put("/payments/update", recorreectionItem)
          .then((response) => {
            if (response.data.status) {
              toast.success(
                "Following payment set to not approved successfully!"
              );
              fetchData();
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            console.log("Error in not approve: ", err);
            toast.error("An error occurred. Please try again.");
          });
      }
      setOpenNotApprove(false);
      setSelectedPaymentId("");
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsNotApproving(false);
    }
  };

  const handleClose = async () => {
    setSelectedPaymentId("");
    setOpenApprove(false);
    setOpenNotApprove(false);
    setPriceError(false);
  };

  return (
    <Container>
      <IconButton onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>

      <Box mb={4}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center", mb: 4 }}>
          Payment Management
        </Typography>
      </Box>

      <Box mb={4}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="New Payments" />
          <Tab label="Approved Payments" />
          <Tab label="Declined Payments" />
        </Tabs>
      </Box>

      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchInputChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ marginLeft: "10px" }}
          disabled={isSearching}
        >
          {isSearching ? <CircularProgress size={24} /> : "Search"}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleReset}
          style={{ marginLeft: "10px" }}
        >
          Reset
        </Button>
      </Box>

      <Box>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography variant="body1" color="error"></Typography>
        ) : filteredPayments.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Student Email</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Student Full Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Submit Date</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Transfer Date</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Price</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Slip</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Status</strong>
                    </TableCell>
                    {currentTab === 0 && (
                      <TableCell>
                        <strong>Actions</strong>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredPayments.map((payment, index) => (
                    <TableRow key={index}>
                      <TableCell>{payment.user.email}</TableCell>
                      <TableCell>{payment.user.full_name}</TableCell>
                      <TableCell>{payment.submit_date}</TableCell>
                      <TableCell>{payment.transfer_date}</TableCell>
                      <TableCell>{payment.price}</TableCell>
                      <TableCell>
                        <img
                          alt="Profile"
                          src={payment.image_link}
                          style={{
                            width: "100px",
                            height: "100px",
                            cursor: "pointer",
                          }}
                          onClick={ () => handleClickImage(payment.image_link)}
                        />
                      </TableCell>
                      <TableCell>{payment.status}</TableCell>
                      <TableCell>
                        {currentTab === 0 && (
                          <Button
                            onClick={() => handleApprove(payment._id)}
                            variant="contained"
                            color="primary"
                            disabled={isApproving}
                          >
                            {isApproving ? <CircularProgress size={24} /> : "Approve"}
                          </Button>
                        )}
                        {currentTab === 0 && (
                          <Button
                            onClick={() => handleNotApprove(payment._id)}
                            variant="contained"
                            color="primary"
                            disabled={isNotApproving}
                          >
                            {isNotApproving ? <CircularProgress size={24} /> : "Not Approve"}
                          </Button>
                        )}
                      </TableCell>

                      <Dialog
                        open={openImage}
                        onClose={handleCloseImage}
                        maxWidth="md"
                        fullWidth
                      >
                        <DialogTitle>Image Preview</DialogTitle>
                        <DialogContent>
                          <img
                            alt="Large Profile"
                            src={largeImage}
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseImage} color="primary">
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalPayments}
              page={paymentPagination.page}
              onPageChange={handleChangePage}
              rowsPerPage={paymentPagination.limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </>
        ) : (
          <Typography variant="body1">No Payment found.</Typography>
        )}
      </Box>

      <Dialog open={openApprove} onClose={handleClose}>
        <DialogTitle>Approve Payment</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            type="number"
            margin="normal"
            label="Price"
            name="price"
            value={price === 0 ? "" : price}
            onChange={(e) => {
              const value = e.target.value;
              setPrice(value === "" ? 0 : Number(value));
              setPriceError(false);
            }}
            inputProps={{
              min: 0,
              step: 1,
            }}
            error={priceError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => clickApprove()}
            color="primary"
            disabled={isApproving}
          >
            {isApproving ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNotApprove} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            If you click submit. This payment going set to Not Approved.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => clickNotApprove()}
            color="primary"
            disabled={isNotApproving}
          >
            {isNotApproving ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Container>
  );
};

export default PaymentManagement;
