import React, { useState, useEffect } from "react";
import axiosClient from "../axiosClient";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyEnrole = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [enrollments, setEnrollments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const userId = localStorage.getItem("USER_ID");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [totalEnrollments, setTotalEnrollments] = useState(0);
  const [enrollmentPagination, setEnrollmentPagination] = useState({
    page: 0,
    limit: 25
  });

  const studentAnswers = {
    toDoStudent: "to-do-student",
    toDoTeacher: "to-do-teacher",
    draft: "draft",
    submitted: "submitted",
  };

  useEffect(() => {
    fetchData();
  }, [currentTab, searchQuery, enrollmentPagination]);

  const fetchData = () => {
    setIsLoading(true);
    const search = searchQuery ? `&search=${searchQuery}` : "";
    axiosClient
      .get(`/paper-enroll?limit=${enrollmentPagination.limit}&page=${enrollmentPagination.page + 1}&user_id=${userId}${search}`)
      .then((response) => {
        if (response.data.status) {
          const enrollPapers = response.data.data.enrollPapers || [];
          setEnrollments(enrollPapers);
          setTotalEnrollments(response.data.data.recordsTotal);
          setIsLoading(false);
        } else if (!response.data.status){
          toast.error(response.data.msg);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching enrollments", error);
        setIsLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setEnrollmentPagination({
      ...enrollmentPagination,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setEnrollmentPagination({
      ...enrollmentPagination,
      limit: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const filteredEnrollments = enrollments.filter((enrollment) => {
    switch (currentTab) {
      case 0: // To-Do
        return enrollment.status === studentAnswers.toDoStudent;
      case 1: // Submitted
        return (
          enrollment.status === studentAnswers.toDoTeacher ||
          enrollment.status === studentAnswers.draft
        );
      case 2: // Feedbacks
        return enrollment.status === studentAnswers.submitted;
      default:
        return false;
    }
  });

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setEnrollmentPagination({ ...enrollmentPagination, page: 0 });
    toast.info("Searching for enrollments...");
  };

  const handleReset = () => {
    setSearchTerm("");
    setSearchQuery("");
    setEnrollmentPagination({ ...enrollmentPagination, page: 0 });
    toast.info("Search reset.");
  };

  const handleView = (paperId) => {
    navigate(`/pdfview/${paperId}`);
    toast.info("Loading paper view...");
  };

  const renderTableHeaders = () => {
    const headers = [
      <TableCell key="name">
        <strong>Paper Name</strong>
      </TableCell>,
    ];

    if (currentTab === 2) {
      // Feedbacks tab
      headers.push(
        <TableCell key="mark">
          <strong>Mark</strong>
        </TableCell>,
        <TableCell key="feedback">
          <strong>Feedback</strong>
        </TableCell>
      );
    }

    headers.push(
      <TableCell key="view">
        <strong>View Paper</strong>
      </TableCell>
    );

    return headers;
  };

  const renderTableRow = (enrollment) => {
    const cells = [<TableCell key="name">{enrollment.paper.title}</TableCell>];

    if (currentTab === 2) {
      // Feedbacks tab
      cells.push(
        <TableCell key="mark">{enrollment.mark || "N/A"}</TableCell>,
        <TableCell key="feedback">{enrollment.feedback || "N/A"}</TableCell>
      );
    }

    cells.push(
      <TableCell key="view">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleView(enrollment.paper._id)}
        >
          View
        </Button>
      </TableCell>
    );

    return cells;
  };

  return (
    <Container>
      <IconButton onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>

      <Box mb={4}>
        <Typography variant="h4" gutterBottom>
          My Enrollments
        </Typography>
      </Box>

      <Box mb={4}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="To-Do" />
          <Tab label="Submitted" />
          <Tab label="Feedback" />
        </Tabs>
      </Box>

      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchInputChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ marginLeft: "10px" }}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleReset}
          style={{ marginLeft: "10px" }}
        >
          Reset
        </Button>
      </Box>

      <Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : filteredEnrollments.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>{renderTableHeaders()}</TableRow>
                </TableHead>
                <TableBody>
                  {filteredEnrollments.map((enrollment, index) => (
                    <TableRow key={index}>{renderTableRow(enrollment)}</TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalEnrollments}
              page={enrollmentPagination.page}
              onPageChange={handleChangePage}
              rowsPerPage={enrollmentPagination.limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </>
        ) : (
          <Typography variant="body1">No enrollments found.</Typography>
        )}
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default MyEnrole;