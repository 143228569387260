// TileView.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const TileView = ({ papersData = [], onTileClick }) => {
  const navigate = useNavigate();

  const handleTileClick = (item) => {
    console.log("id check",item)
    if (item.id) {
      onTileClick(item); // Notify parent to handle folder click
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {papersData.map((folder) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={folder.id}>
            <Box
              onClick={() => handleTileClick(folder)}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                textAlign: "center",
                transition: "transform 0.2s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              
                {/* Folder Icon */}
                  <img
                  src="/folder.svg" // Path to your folder icon
                  alt="Folder Icon"
                  style={{
                    width: "70%",
                    height: "auto",
                  }}
                />
                <Typography 
                  variant="h6"
                  sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  color: "#757575",
                  textTransform: "capitalize",
                  lineHeight: "0",
                  }}
                >{folder.name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

TileView.propTypes = {
  papersData: PropTypes.array.isRequired,
  onTileClick: PropTypes.func.isRequired,
};

export default TileView;
