// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    // apiKey: "AIzaSyCcHT7ANsSMfks8WdCrBLSxCljJBYOBfEI",
    // authDomain: "ayuhasca-bc552.firebaseapp.com",
    // projectId: "ayuhasca-bc552",
    // storageBucket: "ayuhasca-bc552.appspot.com",
    // messagingSenderId: "29638028786",
    // appId: "1:29638028786:web:7b72196c342e693e0fcf6b",

    apiKey: "AIzaSyAhmpy42fm0BLJvHUM67IvQH0CeXiLs_pY",
  authDomain: "agoraacademy-567c0.firebaseapp.com",
  projectId: "agoraacademy-567c0",
  storageBucket: "agoraacademy-567c0.appspot.com",
  messagingSenderId: "729788550571",
  appId: "1:729788550571:web:2529f3d8d12c746543d6ee",
  measurementId: "G-M5Y3G3E76B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

export { storage };
