import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Popper,
  Paper,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import axiosClient from "../axiosClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SearchSection = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("USER_ID");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        searchPapers();
      } else {
        setSearchResults([]);
        setAnchorEl(null); // Hide dropdown when search string is empty
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchPapers = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `/papers?limit=5&page=1&search=${searchTerm}&user_id=${userId}`
      );
      if (response.data.status) {
        setSearchResults(response.data.data.papers);
        console.log(response.data.data.papers);
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error searching papers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setAnchorEl(event.currentTarget);
  };

  const handlePaperClick = (paperId) => {
    navigate(`/papers/${paperId}`);
    handleClosePopper();
  };

  const handleEnroll = (paper) => {
    setSelectedItem(paper);
    setIsDialogOpen(true);
    handleClosePopper();
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
    handleClosePopper();
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setSelectedItem(null);
  };

  const handleAccept = async () => {
    if (selectedItem) {
      setLoading(true);
      try {
        const dataToSubmit = {
          paper_id: selectedItem._id,
          user_id: userId,
        };

        console.log(dataToSubmit);
        const response = await axiosClient.post(
          "/paper-enroll/create",
          dataToSubmit
        );
        if (response.data.status) {
          await toast.success("Enrolled to paper successfully!");
          navigate(`/pdfview/${selectedItem._id}`);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      } catch (error) {
        toast.error("Failed to enroll in paper. Please try again.");
      } finally {
        setLoading(false);
      }
      handleClose();
    }
  };

  const isPopperOpen = Boolean(anchorEl) && searchTerm.length > 0;
  const id = isPopperOpen ? "search-popper" : undefined;

  return (
    <Box className="search" sx={{ border: "none", position: "relative" }}>
      <TextField
        type="text"
        placeholder="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          sx: {
            borderRadius: "20px",
            backgroundColor: "#f5f5f5",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton onClick={handleClearSearch}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popper
        id={id}
        open={isPopperOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClosePopper}>
          <Paper
            elevation={3}
            sx={{
              width: anchorEl ? anchorEl.clientWidth : "auto",
              maxHeight: "300px",
              overflowY: "auto",
              mt: 1,
              borderRadius: "10px",
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
            }}
          >
            {searchResults.length > 0 && (
              <List sx={{ padding: 0 }}>
                {searchResults.map((paper) => (
                  <ListItem
                    key={paper._id}
                    button
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 15px",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    <ListItemText
                      primary={paper.title}
                      secondary={`${paper.subject.name} - ${
                        paper.category.name
                      }${paper.folder ? ` - ${paper.folder.name}` : ""}`}
                      onClick={() => handlePaperClick(paper._id)}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                        },
                        "& .MuiListItemText-secondary": {
                          fontSize: "0.8rem",
                        },
                      }}
                    />
                    {paper.isPurchase || paper.teacher_id === userId ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/pdfview/${paper._id}`)}
                        size="small"
                        sx={{ ml: 2 }}
                      >
                        View
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleEnroll(paper)}
                        size="small"
                        sx={{ ml: 2 }}
                      >
                        Enroll
                      </Button>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>

      {/* Dialog for Enroll Confirmation */}
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Enroll Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
           Purchase confirmation of {selectedItem?.title} for Rs.{" "}
            {selectedItem?.price}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Accept"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchSection;
