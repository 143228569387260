import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Container,
  CssBaseline,
  Box,
  CircularProgress,
} from "@mui/material";
import axiosClient from "../axiosClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../images/logo.jpg"; 


export default function RegisterEmail() {
  const emailRef = useRef();
  const verificationCodeRef = useRef();
  const [verificationSent, setVerificationSent] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);

    const payload = {
      email: emailRef.current.value,
    };

    axiosClient
      .post("/user/email-verify", payload)
      .then(({ data }) => {
        if (data.status) {
          // Check if the response indicates success
          setVerificationSent(true);
          setUserId(data.data._id);
          emailRef.current.value = "";
          toast.success("Verification code sent successfully!!!");
        } else if (!data.status) {
          toast.error(data.msg);
        }
      })
      .catch((err) => {
        console.error("Error sending verification code:", err);
        // const response = err.response;
        // if (response && response.status === 422) {
        //     toast.error("Invalid email address or email already exists.");
        // } else {
        //     toast.error("Something went wrong. Please try again later.");
        // }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleVerificationSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);

    const payload = {
      _id: userId,
      email_verify_code: verificationCodeRef.current.value,
    };

    axiosClient
      .post("/user/email-verify/enter-code", payload)
      .then((response) => {
        if (response.data.status) {
          setIsLoading(false);
          toast.success("Email verified successfully!");
          navigate(`/createaccount?id=${userId}`);
          verificationCodeRef.current.value = "";
        } else if (!response.data.status) {
          toast.error(response.data.msg);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error verifying email:", err);
        setIsLoading(false);
        // const response = err.response;
        // if (response && response.status === 422) {
        //   toast.error("Invalid verification code.");
        // } else {
        //   toast.error("Something went wrong. Please try again later.");
        // }
      });
  };

  return (
    <Container component="main" disableGutters sx={{ width: '100vw', overflowX: 'hidden' }}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            flex: { xs: 1, sm: 0.3 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              maxWidth: '80%',
              height: 'auto',
            }}
          />

<Typography variant="h4" sx={{ mt: 2, mb: 2, fontWeight: 'bold', color: '#000000' }}>
                        Agora Academy
                    </Typography>
        </Box>
        <Box
          sx={{
            flex: { xs: 1, sm: 0.7 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 3,
          }}
        >
          <Box className="login-signup-form animated fadeinDown" sx={{ width: '100%', maxWidth: 400 }}>
            <Typography component="h1" variant="h5" align="center">
              {verificationSent ? "Enter Verification Code" : "Enter Your Email"}
            </Typography>
            {!verificationSent ? (
              <form onSubmit={handleSubmit}>
                <TextField
                  inputRef={emailRef}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  sx={{
                    backgroundColor: '#FEC619',
                    color: '#333333',
                    '&:hover': {
                      backgroundColor: '#e6b000',
                    },
                    mt: 2,
                    mb: 2,
                  }}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Send Verification Code"}
                </Button>
                <Typography
                  component="p"
                  variant="body2"
                  align="center"
                  sx={{ mt: 2 }}
                >
                  Already Have An Account? <Link to="/login">Login</Link>
                </Typography>
              </form>
            ) : (
              <form onSubmit={handleVerificationSubmit}>
                <TextField
                  inputRef={verificationCodeRef}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="verificationCode"
                  label="Verification Code"
                  name="verificationCode"
                  autoComplete="off"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  sx={{
                    backgroundColor: '#FEC619',
                    color: '#333333',
                    '&:hover': {
                      backgroundColor: '#e6b000',
                    },
                    mt: 2,
                    mb: 2,
                  }}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Verify Email"}
                </Button>
              </form>
            )}
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </Container>
  );
}
