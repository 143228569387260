import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, TextField, Typography, Container, CssBaseline, Box, CircularProgress } from "@mui/material";
import axiosClient from "../axiosClient";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import logo from "../images/logo.jpg"; 



export default function ForgetPassword() {
    const emailRef = useRef();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        setIsLoading(true);
        const payload = {
            email: emailRef.current.value,
        };
        axiosClient
            .post("/user/forget-password", payload)
            .then((response) => {
                setIsLoading(false);
                if (response.data.status) {
                    toast.success("Reset code sent successfully. Please check your email.");
                    setTimeout(() => {
                        navigate("/enterresetcode", { state: { email: payload.email } });
                    }, 2000);
                } else if (!response.data.status){
                    toast.error(response.data.msg);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.error("Error sending reset code:", err);
            });
    };

    return (
        <Container component="main" disableGutters sx={{ width: '100vw', overflowX: 'hidden' }}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    minHeight: '100vh',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        flex: { xs: 1, sm: 0.3 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                    }}
                >
                    <img
                        src={logo} // Using the logo from the context
                        alt="Logo"
                        style={{
                            maxWidth: '80%',
                            height: 'auto',
                        }}
                    />
                       <Typography variant="h4" sx={{ mt: 2, mb: 2, fontWeight: 'bold', color: '#000000' }}>
                        Agora Academy
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flex: { xs: 1, sm: 0.7 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                    }}
                >
                    <Box className="login-signup-form animated fadeinDown" sx={{ width: '100%', maxWidth: 400 }}>
                        <Typography component="h1" variant="h5" align="center">
                            Forgot Your Password?
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                inputRef={emailRef}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                                sx={{
                                    backgroundColor: '#FEC619',
                                    color: '#333333',
                                    '&:hover': {
                                        backgroundColor: '#e6b000',
                                    },
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Reset Password'}
                            </Button>
                            <Typography component="p" variant="body2" align="center" sx={{ mt: 2 }}>
                                Remembered your password? <Link to="/login">Login here</Link>
                            </Typography>
                        </form>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
        </Container>
    );
}
