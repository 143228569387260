import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Fullscreen as FullscreenIcon,
} from "@mui/icons-material";
import axiosClient from "../axiosClient";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AnswerScriptStudentView = () => {
  const navigate = useNavigate();
  const { paperid } = useParams();
  const iframeRef = useRef(null);
  const [paperData, setPaperData] = useState({});
  const [firebaseStorageUrl, setFirebaseStorageUrl] = useState("");
  const userId = localStorage.getItem("USER_ID");
  const [isLoading, setIsLoading] = useState(true);
  const [paperEnrollData, setPaperEnrollData] = useState(null);
  const [error, setError] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosClient.get(`/papers/${paperid}/${userId}`);
        if (response.data.status === true) {
          setPaperData(response.data.data.paper);
          setFirebaseStorageUrl(response.data.data.paper.answer_link);
          
          if (!response.data.data.paper.answer_link && !response.data.data.paper.video_link) {
            setError("Please complete your answer and submit it to your teacher to view this answer!!!");
            setOpenErrorDialog(true);
          }
        } else if (response.data.status === false) {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching paper data:", error);
        setError("Failed to load paper data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [paperid, userId]);

  //papers validation failed: folder_id: Cast to ObjectId failed for value "" (type string) at path "folder_id" because of "BSONError"

  useEffect(() => {
    const fetchPaperEnrollData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosClient.get(
          `/paper-enroll/${paperid}/${userId}`
        );
        if (response.data.status) {
          setPaperEnrollData(response.data.data);
        } else {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching paper enrollment data:", error);
        setError("Failed to load enrollment data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchPaperEnrollData();
  }, [paperid, userId]);

  const handleClickPaperView = () => {
    navigate(`/pdfview/${paperid}`);
  };

  const handleFullScreen = () => {
    if (iframeRef.current.requestFullscreen) {
      iframeRef.current.requestFullscreen();
    } else if (iframeRef.current.mozRequestFullScreen) {
      iframeRef.current.mozRequestFullScreen();
    } else if (iframeRef.current.webkitRequestFullscreen) {
      iframeRef.current.webkitRequestFullscreen();
    } else if (iframeRef.current.msRequestFullscreen) {
      iframeRef.current.msRequestFullscreen();
    }
  };

  if (isLoading) {
    return (
      <Container sx={styles.loadingContainer}>
        <CircularProgress 
        size={30}
        sx={{
          color: "#1976d2",
          animationDuration: "1s",
        }}/>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading paper data...
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
        <IconButton onClick={handleClickPaperView} sx={{ ...styles.backButton,  left: 0 }}>
          <ArrowBackIcon />
        </IconButton>

        
      <Box sx={styles.headerContainer}>
      
      <Box
              sx={{
                padding: "20px",
                backgroundColor: "#FFF5D9",
                borderRadius: "8px",
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                marginTop: "20px",
                width: "100%",
              }}
            >
<Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "#202124",
                  marginBottom: "8px",
                }}
              >Answers for {paperData.title ? `${paperData.title}` : "Untitled Paper"}
          </Typography>
          {paperData.teacher?.full_name && (
            <Typography variant="subtitle1" sx={{ color: "#5f6368", marginBottom: "4px" }}
>
              by {paperData.teacher.full_name}
            </Typography>
          )}
          {paperData.subject?.name && (
            <Typography variant="subtitle1" sx={styles.headerAuthor}>
              Subject: {paperData.subject.name}
            </Typography>
          )}
          {paperData.category?.name && (
            <Typography variant="subtitle1" sx={styles.headerAuthor}>
              Category: {paperData.category.name}
            </Typography>
          )}
          {paperData.folder?.name && (
            <Typography variant="subtitle1" sx={styles.headerAuthor}>
              Unit: {paperData.folder.name}
            </Typography>
          )}
          {paperData.duration && (
            <Typography variant="subtitle1" sx={styles.headerAuthor}>
              Duration: {paperData.duration}
            </Typography>
          )}
        </Box>
      </Box>

      {paperEnrollData && paperEnrollData.status !== "to-do-student" && (
        <Box sx={styles.marksContainer}>
          {paperEnrollData.student_link && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(paperEnrollData.student_link, "_blank")
              }
              sx={styles.teacherPaperButton}
            >
              View My Submitted Answer
            </Button>
          )}
        </Box>
      )}

      {paperEnrollData && paperEnrollData.status === "submitted" && (
        <Box sx={styles.marksContainer}>
          {paperEnrollData.mark && (
            <Typography variant="h6">
              Your Marks: {paperEnrollData.mark}
            </Typography>
          )}
          {paperEnrollData.student_answer_time && (
            <Typography variant="h6">
              Your Answer Time: {(() => {
                const hours = Math.floor(paperEnrollData.student_answer_time / 3600);
                const minutes = Math.floor((paperEnrollData.student_answer_time % 3600) / 60);
                const seconds = paperEnrollData.student_answer_time % 60;
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
              })()}
            </Typography>
          )}
          {paperEnrollData.feedback && (
            <Typography variant="h6" sx={styles.feedback}>
              Teacher's Feedback: {paperEnrollData.feedback}
            </Typography>
          )}
          {paperEnrollData.teacher_link && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(paperEnrollData.teacher_link, "_blank")
              }
              sx={styles.teacherPaperButton}
            >
              View Teacher Marked Paper
            </Button>
          )}
        </Box>
      )}

      {firebaseStorageUrl && (
        <Box    sx={{
          position: "relative",
          height: "60vh",
          p: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
          <iframe
            ref={iframeRef}
            src={`${firebaseStorageUrl}#toolbar=0&navpanes=0&scrollbar=0`}

            style={{ width: "100%", height: "100%", border: "none" }}
            title="PDF Viewer"
          />
           {/* Full Screen Button */}
           <Button
                    onClick={handleFullScreen}
                    startIcon={<FullscreenIcon />}
                  >
                    Full Screen
                  </Button>

                    {/* Watermark */}
                    <Typography sx={styles.watermark}>Agora Academy</Typography>
        </Box>

      )}

      {paperData && paperData.video_link && (
        <Box sx={styles.videoContainer}>
          <iframe
            width="100%"
            height="500"
            src={paperData.video_link}
            title="Video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      )}

      <Dialog
        open={openErrorDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">{"Error"}</DialogTitle>
        <DialogContent>
          <Typography>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickPaperView} color="primary">
            Go Back
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: { xs: "10px", md: "20px" },
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "20px",
    alignItems: "center",
  },
  backButton: {
    marginRight: "20px",
  },
  headerPaper: {
    flex: 1,
    height: { xs: "100px", md: "150px" },
    borderRadius: "10px",
    backgroundColor: "#ddd",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px",
  },
  headerTitle: {
    margin: 0,
    fontSize: { xs: "24px", md: "36px" },
  },
  headerAuthor: {
    margin: 0,
    fontSize: { xs: "14px", md: "18px" },
  },
  paperContainer: {
    width: "100%",
    height: { xs: "50vh", md: "60vh" },
    border: "1px solid #ccc",
    borderRadius: "10px",
    backgroundColor: "#f5f5f5",
    position: "relative",
  },
  fullScreenButton: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    padding: "5px 10px",
    backgroundColor: "#1976d2",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#1565c0",
    },
  },
  watermark: {
    position: "absolute",
    opacity: 0.2,
    fontSize: 70,
    zIndex: 0,
  },
  marksContainer: {
    marginTop: "20px",
    width: "100%",
    textAlign: "center",
  },
  feedback: {
    marginTop: "10px",
    color: "darkgreen",
  },
  teacherPaperButton: {
    marginTop: "10px",
  },
  videoContainer: {
    width: "100%",
    marginTop: "20px",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
};

export default AnswerScriptStudentView;
