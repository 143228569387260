import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StudentViewOfPaper = () => {
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => setTime(prevTime => prevTime + 1), 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning]);

  const handleClickAnswerView = () => {
    navigate(`/subject/viewanswer`);
  };

  if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
    alert("Screen capturing is not allowed.");
  }

  const handleFullScreen = () => {
    if (iframeRef.current.requestFullscreen) {
      iframeRef.current.requestFullscreen();
    } else if (iframeRef.current.mozRequestFullScreen) { // Firefox
      iframeRef.current.mozRequestFullScreen();
    } else if (iframeRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
      iframeRef.current.webkitRequestFullscreen();
    } else if (iframeRef.current.msRequestFullscreen) { // IE/Edge
      iframeRef.current.msRequestFullscreen();
    }
  };

  const handlePlayPause = () => {
    setIsRunning(!isRunning);
    toast.info(isRunning ? "Timer paused" : "Timer started");
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  const firebaseStorageUrl = 'https://firebasestorage.googleapis.com/v0/b/ayuhasca-bc552.appspot.com/o/sALSyl%20Maths.pdf?alt=media&token=64342424-3e89-4486-884e-81994bf429e6'

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Modal Paper 00</h2>
      <div style={styles.contentContainer}>
        <div style={styles.paperContainer}>
          <iframe
            ref={iframeRef}
            src={firebaseStorageUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="PDF Viewer"
          />
          <button onClick={handleFullScreen} style={styles.fullScreenButton}>
            Full Screen
          </button>
        </div>
        <div style={styles.rightPanel}>
          <div style={styles.timerContainer}>
            <button onClick={handlePlayPause} style={styles.playButton}>
              {isRunning ? '⏸️' : '⏯️'}
            </button>
            <span style={styles.timer}>{formatTime(time)}</span>
          </div>
          <div style={styles.actionsContainer}>
            <button style={styles.uploadButton}>
              <span role="img" aria-label="upload">☁️</span> Upload answers
            </button>
            <button onClick={handleClickAnswerView} style={styles.viewButton}>
              <span role="img" aria-label="view">👁️</span> View Answers
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
    width: '100%',
  },
  title: {
    marginBottom: '10px',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  paperContainer: {
    width: '70%',
    height: '400px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#ddd',
    position: 'relative',
  },
  fullScreenButton: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    padding: '5px 10px',
    backgroundColor: '#004d40',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '20px',
  },
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  playButton: {
    fontSize: '20px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  timer: {
    fontSize: '18px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  uploadButton: {
    padding: '10px 20px',
    backgroundColor: '#004d40',
    color: 'black',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  viewButton: {
    padding: '10px 20px',
    backgroundColor: '#ccc',
    color: '#666',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
};

export default StudentViewOfPaper;