import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Container,
  CssBaseline,
  Box,
  CircularProgress,
} from "@mui/material";
import axiosClient from "../axiosClient";
import { useStateContext } from "../contexts/contextprovider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Register() {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const { setUser, setToken } = useStateContext();
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("id"); // Get userId from URL
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);

    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      toast.error("Passwords do not match");
      setIsLoading(false);
      return;
    }

    const payload = {
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      phone: phoneRef.current.value,
      password: passwordRef.current.value,
      _id: userId, // Pass the userId to the payload
    };
    console.log("create", payload);
    // axiosClient
    //   .post("/user/create", payload)
    //   .then(({ response }) => {
    //     // setToken(data.token);
    //     // // Uncomment if you want to set user data
    //     // // setUser(data.user);
    //     // toast.success("Registration successful!");
    //     // setIsLoading(false);
    //     // localStorage.setItem('USER_ID',data._id);

    //     if (response.data.status) {
    //       setToken(response.data.token);
    //       // Uncomment if you want to set user data
    //       // setUser(data.user);
    //       setIsLoading(false);
    //       localStorage.setItem("USER_ID", response.data._id);
    //       toast.success("Registration successful! Redirecting...");
    //     } else if (!response.data.status) {
    //       toast.error(response);
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     const response = err.response;
    //     if (response && response.status === 422) {
    //       console.log(response.data.errors);
    //       toast.error("Registration failed. Please try again.");
    //     }
    //     setIsLoading(false);
    //   });

    axiosClient
            .post("/user/create", payload)
            .then((response) => {
                if (response.data.status) {
                    setIsLoading(false);
                    setUser(response.data.data._id);
                    setToken(response.data.token);
                    toast.success('Registration successful! Redirecting...');
                } else if (!response.data.status){
                    toast.error(response.data.msg);
                    setIsLoading(false);
                }
              })
            .catch(err => {
                toast.error(err);
                const response = err.response;
                if (response) {
                    console.log(response.data.errors);
                    console.error(response);
                } else {
                    // Handle other errors
                    console.error('An error occurred. Please try again.');
                }
            });
  };

  return (
    <Container component="main" disableGutters sx={{ width: "100vw" }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          width: "100%",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            flex: 1,
            backgroundColor: "#FEC619",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8xFCj5v4Ip9tFzNRqXKVsyCCLNP0SYgNuUg&s"
            alt="Logo"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="login-signup-form animated fadeinDown"
            sx={{ width: "100%", maxWidth: 400 }}
          >
            <Typography component="h1" variant="h5" align="center">
              Create A New Account
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                inputRef={firstNameRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoFocus
              />
              <TextField
                inputRef={lastNameRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
              />
              <TextField
                inputRef={phoneRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
              />
              <TextField
                inputRef={passwordRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <TextField
                inputRef={confirmPasswordRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
                sx={{
                  backgroundColor: "#FEC619",
                  color: "#333333",
                  "&:hover": {
                    backgroundColor: "#e6b000",
                  },
                  mt: 2,
                  mb: 2,
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Register"}
              </Button>
              <Typography
                component="p"
                variant="body2"
                align="center"
                sx={{ mt: 2 }}
              >
                Already Have An Account? <Link to="/login">Login</Link>
              </Typography>
            </form>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </Container>
  );
}
