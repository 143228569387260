import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import axiosClient from "../axiosClient"; // Import axiosClient
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TileView2 = ({ papersData, onTileClick }) => {
  // Ensure papersData is an array
  const dataArray = Array.isArray(papersData) ? papersData : [];

  // State for managing the dialog
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [error, setError] = useState("");
  const userId = localStorage.getItem("USER_ID");
  const [loading, setLoading] = useState(false);

  const handleEnrollClick = (item, event) => {
    event.stopPropagation(); // Prevent the click event from triggering the tile click
    setSelectedItem(item);
    setOpen(true);
  };

  const handleViewClick = (item) => {
    onTileClick(item); // Pass the selectedItem to the onTileClick function
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const handleAccept = async () => {
    if (selectedItem) {
      setLoading(true);
      // Handle the acceptance logic here
      try {
        const dataToSubmit = {
          paper_id: selectedItem._id,
          user_id: userId,
        };

        // Submit the data to your backend using Axios
        console.log(dataToSubmit);
        const response = await axiosClient.post(
          "/paper-enroll/create",
          dataToSubmit
        );
        if (response.data.status) {
          toast.success("Enroll to paper successfully!!!");
          onTileClick(selectedItem); // Pass the selectedItem to the onTileClick function
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("enroll error" + error);
      } finally {
        setLoading(false);
      }
      handleClose();
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {dataArray.map((file) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
            <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '8px', background:"#FFF5D9" }}>
              {/* Clickable File Icon */}
              <Box
                onClick={() => {
                  if (!file.isPurchase && file.teacher_id !== userId) {
                    // eslint-disable-next-line no-restricted-globals
                    handleEnrollClick(file, event); // Enroll logic
                  } else {
                    handleViewClick(file); // View logic
                  }
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  textAlign: "center",
                  borderRadius: 3,
                  background: "#FEC619",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.15)",
                    background: "linear-gradient(145deg, #ffffff, #f4f4f4)",
                  },
                }}
              >
                {/* File Icon */}
                <img
                  src={file.display_image_link} // Path to your file icon
                  alt="File Icon"
                  style={{
                    width: "100%", // Adjust the icon size to cover the width
                    aspectRatio:1,
                    objectFit: "cover", // Cover the container without losing aspect ratio
                    marginBottom: "10px", // Space between icon and text
                    borderRadius: "10px", // Slightly rounded corners for the image
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Icon shadow for depth
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1rem",
                    color: "#333",
                    mb: 1,
                  }}
                >
                  {file.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#666",
                    mb: 0.5,
                  }}
                >
                  by {file.teacher.full_name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#000000",
                  }}
                >
                  Price: Rs. {file.price}
                </Typography>
                {!file.isPurchase && (
  <Typography
  variant="body2"
  sx={{
    display: "inline-block", // Ensures proper layout
    fontSize: "0.9rem",
    color: "#ffffff", // White text color
    backgroundColor: "#1976d2", // Add a blue background
    padding: "4px 8px", // Padding for a button-like appearance
    borderRadius: "8px", // Rounded edges
    fontWeight: 500, // Make the text slightly bold
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", // Subtle shadow for elevation
    position: "relative", // Required for the pseudo-element
    marginBottom: "12px", // Space below the component

  }}
>
  Purchase
</Typography>

)}

              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for Enroll Confirmation */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enroll Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
          Purchase {selectedItem?.title} for Rs.{" "}
            {selectedItem?.price}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Close
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Accept"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

TileView2.propTypes = {
  papersData: PropTypes.array,
  onTileClick: PropTypes.func.isRequired,
};

export default TileView2;
