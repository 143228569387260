import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import SearchSection from "./SearchSection";
import SectionSelector from "./SectionSelector";
import FrontView from "./FrontView";
import TileView from "./TileView";
import TileView2 from "./TileView2";
import axiosClient from "../axiosClient";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const LayerZero = () => {
  const { selectedSubject } = useOutletContext();
  const [categories, setCategories] = useState([]);
  const [foldersData, setFoldersData] = useState({});
  const [filesData, setFilesData] = useState([]);
  const [data, setData] = useState({});
  const [clickedTile, setClickedTile] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const [showTileView2, setShowTileView2] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("USER_ID");
  const [isLoading, setIsLoading] = useState(false);
  const [navigationHistory, setNavigationHistory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [totalPapers, setTotalPapers] = useState(0);
  const [paperPagination, setPaperPagination] = useState({
    page: 0,
    limit: 5,
  });

  useEffect(() => {
    setIsLoading(true);
    const subjectId = selectedSubject
      ? selectedSubject
      : "6688133cafdceec0e54b1bb8";
    axiosClient
      .get(`/categories?limit=20&page=1&subject_id=${subjectId}`)
      .then((response) => {
        const categories = response.data.data.categories || [];
        setCategories(categories);

        const initialFoldersData = categories.reduce((acc, category) => {
          acc[category.name] = [];
          return acc;
        }, {});

        const fetchFoldersPromises = categories.map((category) => {
          if (category.has_folder) {
            return axiosClient
              .get(`/folders?limit=50&page=1&category_id=${category._id}`)
              .then((folderResponse) => {
                const folders = folderResponse.data.data.folders || [];
                const updatedFoldersData = { ...initialFoldersData };
                updatedFoldersData[category.name] = folders;
                return updatedFoldersData;
              })
              .catch((folderError) => {
                console.error(
                  `Error fetching folders for category ${category._id}`,
                  folderError
                );
                return initialFoldersData;
              });
          } else {
            return Promise.resolve(initialFoldersData);
          }
        });

        Promise.all(fetchFoldersPromises).then((results) => {
          const finalFoldersData = results.reduce((acc, data) => {
            Object.keys(data).forEach((key) => {
              if (Array.isArray(data[key])) {
                acc[key] = [...(acc[key] || []), ...data[key]];
              }
            });
            return acc;
          }, {});

          setFoldersData(finalFoldersData);
        });
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
        toast.error("Failed to load categories. Please try again.");
      })
      .finally(() => setIsLoading(false));
  }, [selectedSubject]);

  useEffect(() => {
    const transformedData = Object.keys(foldersData).reduce(
      (acc, categoryName) => {
        acc[categoryName] = foldersData[categoryName].map((folder) => ({
          id: folder._id,
          name: folder.name,
          isFolder: true,
        }));
        return acc;
      },
      {}
    );

    setData(transformedData);
  }, [foldersData]);

  useEffect(() => {
    const tabLabels = Object.keys(data);
    if (tabLabels.length > 0) {
      setActiveTab(tabLabels[0]);
    }
  }, [data]);

  useEffect(() => {
    if (selectedFolder) {
      setIsLoading(true);
      axiosClient
        .get(
          `/papers?limit=${paperPagination.limit}&page=${
            paperPagination.page + 1
          }&parent_id=${selectedFolder.id}&user_id=${userId}`
        )
        .then((response) => {
          if (response.data.status) {
            setFilesData(response.data.data.papers || []);
            setTotalPapers(response.data.data.recordsTotal);
          } else {
            toast.error(response.data.msg);
          }
        })
        .catch((error) => {
          console.error(
            `Error fetching files for folder ${selectedFolder.id}`,
            error
          );
          toast.error("Failed to load papers. Please try again.");
          setFilesData([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedFolder, paperPagination]);

  useEffect(() => {
    if (selectedCategory && !selectedCategory.has_folder) {
      setIsLoading(true);
      axiosClient
        .get(
          `/papers?limit=${paperPagination.limit}&page=${
            paperPagination.page + 1
          }&parent_id=${selectedCategory._id}&user_id=${userId}`
        )
        .then((response) => {
          if (response.data.status) {
            setFilesData(response.data.data.papers || []);
            setTotalPapers(response.data.data.recordsTotal);
            setShowTileView2(true);
          } else {
            toast.error(response.data.msg);
          }
        })
        .catch((error) => {
          console.error(
            `Error fetching papers for category ${selectedCategory._id}`,
            error
          );
          toast.error("Failed to load papers. Please try again.");
          setFilesData([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setSelectedCategory(null);
    }
  }, [selectedCategory, paperPagination]);

  const handleTabChange = async (newValue) => {
    setActiveTab(newValue);
    setClickedTile(null);
    setShowTileView2(false);
    setNavigationHistory([]);
    setSelectedFolder(null);
    setPaperPagination({ page: 0, limit: 5 });

    const selectedCategory = categories.find(
      (category) => category.name === newValue
    );
    setSelectedCategory(selectedCategory);
  };

  const handleChangePaperPage = (event, newPage) => {
    setPaperPagination({
      ...paperPagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPaperPage = (event) => {
    setPaperPagination({
      ...paperPagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleTileClick = async (tile) => {
    if (tile.isFolder) {
      setNavigationHistory((prev) => [...prev, { activeTab, clickedTile }]);
      setClickedTile(tile);
      setShowTileView2(true);
      setFilesData([]);
      setSelectedFolder(tile);
    } else {
      setClickedTile(tile);
      setShowTileView2(false);
    }
  };

  const handleTileClick2 = (file) => {
    navigate(`/pdfview/${file._id}`);
  };

  const handleBack = () => {
    if (navigationHistory.length > 0) {
      const lastState = navigationHistory.pop();
      setActiveTab(lastState.activeTab);
      setClickedTile(lastState.clickedTile);
      setShowTileView2(lastState.clickedTile !== null);
      setNavigationHistory([...navigationHistory]);
      setSelectedFolder(null);
      setPaperPagination({ page: 0, limit: 5 });

      if (lastState.clickedTile === null) {
        setFilesData([]);
      } else {
        handleTileClick(lastState.clickedTile);
      }
    }
  };

  const renderTileView = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <CircularProgress
            size={30}
            sx={{
              color: "#1976d2",
              animationDuration: "1s",
            }}
          />
        </Box>
      );
    }
    if (showTileView2) {
      if (filesData.length === 0) {
        return (
          <Typography
            variant="h6"
            align="center"
            sx={{
              color: "#555", // Subtle text color
              fontStyle: "italic", // Optional: to differentiate from regular text
              padding: "16px", // Add some spacing
              maxWidth: "400px", // Optional: limit width for readability
              margin: "auto", // Center horizontally
              marginTop: "50px", // Add vertical spacing
            }}
          >
            No papers found!
          </Typography>
        );
      }
      return (
        <TileView2 papersData={filesData} onTileClick={handleTileClick2} />
      );
    } else {
      if (data[activeTab]?.length === 0) {
        return (
          <Typography variant="h6" align="center">
            No folders or papers in this category
          </Typography>
        );
      }
      return (
        <TileView
          papersData={data[activeTab] || []}
          onTileClick={handleTileClick}
        />
      );
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <SearchSection />
      <SectionSelector
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        tabLabels={Object.keys(data)}
      />
      <FrontView />
      {navigationHistory.length > 0 && (
        <Box sx={{ position: "absolute", top: 50, left: 10 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            color="primary"
          >
            Back
          </Button>
        </Box>
      )}
      {renderTileView()}

      
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        }}
      >

        
        {(navigationHistory.length > 0 ||
          (selectedCategory && !selectedCategory.has_folder)) && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={paperPagination.limit}
              onChange={handleChangeRowsPerPaperPage}
              size="small"
              sx={{ mr: 2 }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
            </Select>
            <Pagination
              component="div"
              count={Math.ceil(totalPapers / paperPagination.limit)}
              page={paperPagination.page + 1}
              onChange={(event, newPage) =>
                handleChangePaperPage(event, newPage - 1)
              }
              color="primary"
              size="small"
            />
            <Typography variant="body2" sx={{ ml: 2 }}>
              Page {paperPagination.page + 1} of{" "}
              {Math.ceil(totalPapers / paperPagination.limit)}
            </Typography>
          </Box>
        )}
      </Box>
      <ToastContainer />
    </div>
  );
};

export default LayerZero;
