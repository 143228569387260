import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Card,
  CardMedia,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CloudUpload } from "@mui/icons-material";
import axiosClient from "../axiosClient";
import { uploadFile, deleteFile } from "../utils/firebaseUtils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../Components/OtherStuff/firebaseConfig";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

const PopupMessagePage = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [value, setValue] = useState(0);
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openEditModal, setEditOpenModal] = useState(false);
  const userId = localStorage.getItem("USER_ID");
  const [uploading, setUploading] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [imageLink, setImageLink] = useState(null);
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [totalPayments, setTotalPayments] = useState(0);
  const [paymentPagination, setPaymentPagination] = useState({
    page: 0,
    limit: 25,
  });

  useEffect(() => {
    const fetchPayments = async () => {
      setFetchLoading(true);
      try {
        const response = await axiosClient.get(
          `/payments?limit=${paymentPagination.limit}&page=${
            paymentPagination.page + 1
          }&user_id=${userId}`
        );
        if (response.data.status) {
          const payments = response.data.data.payments || [];
          setPayments(payments);
          setTotalPayments(response.data.data.recordsTotal);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching payments", error);
        toast.error("Error fetching payments");
      } finally {
        setFetchLoading(false);
      }
    };

    fetchPayments();
  }, [userId, image, submitDelete, paymentPagination]);

  const handleChangePage = (event, newPage) => {
    setPaymentPagination({
      ...paymentPagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPaymentPagination({
      ...paymentPagination,
      limit: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      setImageLink(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setHasImage(true);
        setUploading(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const timestamp = Date.now();
      const fileName = `${timestamp}_${imageLink.name}`;
      const display_image_link = await uploadFile(
        imageLink,
        `images/${fileName}`
      );

      const dataToSubmit = {
        user_id: userId,
        image_link: display_image_link,
      };

      console.log("dataToSubmit", dataToSubmit);
      const response = await axiosClient.post("/payments/create", dataToSubmit);

      if (response.data.status) {
        toast.success("Payment submitted successfully!");
        handleClose()
        // Refresh the page after successful submission
     //   window.location.reload();
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("Error submitting payment!");
    } finally {
      setLoading(false);
      setImage(null);
      setHasImage(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = (payment) => {
    setSelectedPayment(payment);
    setEditOpenModal(true);
  };

  const handleDelete = (payment) => {
    setSelectedPayment(payment);
    setDeleteOpenModal(true);
  };

  const handleEditSubmit = async () => {
    setLoading(true);
    try {
      if (selectedPayment.image_link) {
        const fileRef = ref(storage, selectedPayment.image_link);
        try {
          await getDownloadURL(fileRef);
          await deleteFile(selectedPayment.image_link);
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            console.log("File does not exist in Firebase storage");
          } else {
            console.error("Error checking/deleting file:", error);
          }
        }
      }

      const timestamp = Date.now();
      const fileName = `${timestamp}_${selectedPayment._id}`;
      const display_image_link = await uploadFile(
        imageLink,
        `images/${fileName}`
      );

      const dataToSubmit = {
        _id: selectedPayment._id,
        image_link: display_image_link,
      };

      const response = await axiosClient.put("/payments/update", dataToSubmit);

      if (response.data.status) {
        toast.success("Payment updated successfully!!!");


      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error updating payment:", error);
      toast.error("Error updating payment");
    } finally {
      setLoading(false);
      setImage(null);
      setHasImage(false);
      setEditOpenModal(false);
    }
  };

  const handleDeleteSubmit = async () => {
    setSubmitDelete(false);
    setLoading(true);
    try {
      const response = await axiosClient.delete(
        `/payments/delete/${selectedPayment._id}`
      );

      if (response.data.status) {
        if (selectedPayment.image_link) {
          const fileRef = ref(storage, selectedPayment.image_link);
          try {
            await getDownloadURL(fileRef);
            await deleteFile(selectedPayment.image_link);
          } catch (error) {
            if (error.code === "storage/object-not-found") {
              console.log("File does not exist in Firebase storage");
            } else {
              console.error("Error checking/deleting file:", error);
            }
          }
        }
        toast.success("Payment deleted successfully!");
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error delete payment:", error);
      toast.error("Error deleting payment");
    } finally {
      setLoading(false);
      setDeleteOpenModal(false);
      setSubmitDelete(true);
    }
  };

  const handleModalClose = () => {
    setEditOpenModal(false);
    setDeleteOpenModal(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100vh", padding: "20px" }}
    >
      <IconButton onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>
      <IconButton onClick={() => navigate("/")}>
        {/* <ArrowBackIcon /> */}
      </IconButton>
      <Typography variant="h4" gutterBottom>
        Topup your account
      </Typography>
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "16px",
          padding: "20px",
          width: "100%",
          maxWidth: "500px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Payment Instructions
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          Please follow these steps to complete your payment:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          ( Minimum payment Rs.500){" "}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          <strong>1. Pay to the bank details below:</strong>
        </Typography>
        <Box
          sx={{
            backgroundColor: "#FEC619",
            borderRadius: "8px",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ marginBottom: 1, textAlign: "center" }}
          >
            <strong>Name:</strong> RMSK Rathnayaka
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 1, textAlign: "center" }}
          >
            <strong>Bank:</strong> Bank Of Ceylon
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 1, textAlign: "center" }}
          >
            <strong>Account Number:</strong> 74884141
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 1, textAlign: "center" }}
          >
            <strong>Branch:</strong> Pemaduwa
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          <strong>2. Upload the payment slip as an image or PDF.</strong>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          <strong>
            3. After the confirmation, your topup balance will be updated
          </strong>
        </Typography>
      </Box>

      <Tabs value={value} onChange={handleChangeTab} sx={{ marginTop: 2 }}>
        <Tab label="Payslip upload" />
        <Tab label="Submitted Payments" />
      </Tabs>

      {value === 0 && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
          <Typography variant="h6" gutterBottom>
            Upload your Payslip
          </Typography>
          <input
            accept="image/"
            type="file"
            id="image-upload"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <label htmlFor="image-upload">
            <IconButton color="primary" component="span" disabled={uploading}>
              {uploading ? <CircularProgress size={24} /> : <CloudUpload />}
            </IconButton>
          </label>
          {image && (
            <Card sx={{ maxWidth: 400, mt: 2 }}>
              <CardMedia
                component="img"
                height="200"
                image={image}
                alt="Uploaded Image"
              />
            </Card>
          )}
          <Box mt={3} display="flex" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading || !hasImage}
              sx={{
                backgroundColor: "#fec619", // Correct property name
                color: "black",
                "&:hover": {
                  backgroundColor: "#e65a30", // Optional: Define hover color
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                backgroundColor: "red", // Correct property name
                color: "white",
                "&:hover": {
                  backgroundColor: "red", // Optional: Define hover color
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      )}

      {value === 1 && (
        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Submitted Payments
          </Typography>
          {fetchLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Submit Date</TableCell>
                    <TableCell>Image Link</TableCell>
                    <TableCell>Transfer Status</TableCell>
                    <TableCell>Transfer Date</TableCell>
                    <TableCell>Transfer Price</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment) => (
                    <TableRow key={payment._id}>
                      <TableCell>
                        {new Date(payment.submit_date).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <img
                          alt="Profile"
                          src={payment.image_link}
                          style={{
                            width: "100px",
                            height: "100px",
                            cursor: "pointer",
                          }}
                        />
                      </TableCell>
                      <TableCell>{payment.status}</TableCell>
                      <TableCell>
                        {payment.transfer_date
                          ? new Date(payment.transfer_date).toLocaleString()
                          : "N/A"}
                      </TableCell>
                      <TableCell>{payment.price || "N/A"}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={() => handleEdit(payment)}
                          sx={{
                            backgroundColor: "#fec619", // Correct property name
                            color: "black",
                            "&:hover": {
                              backgroundColor: "#e65a30", // Optional: Define hover color
                            },
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDelete(payment)}
                          //sx={{ ml: 1 }}
                          sx={{
                            ml: 1,
                            backgroundColor: "red", // Correct property name
                            "&:hover": {
                              backgroundColor: "red", // Optional: Define hover color
                            },
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={totalPayments}
                page={paymentPagination.page}
                onPageChange={handleChangePage}
                rowsPerPage={paymentPagination.limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableContainer>
          )}
        </Box>
      )}

      {/* Edit Modal */}
      <Modal open={openEditModal} onClose={handleModalClose}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Upload an Image
          </Typography>

          <input
            accept="image/*"
            type="file"
            id="image-upload-edit"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <label htmlFor="image-upload-edit">
            <IconButton color="primary" component="span" disabled={uploading}>
              {uploading ? <CircularProgress size={24} /> : <CloudUpload />}
            </IconButton>
          </label>
          {image && (
            <Card sx={{ maxWidth: 400, mt: 2 }}>
              <CardMedia
                component="img"
                height="200"
                image={image}
                alt="Uploaded Image"
              />
            </Card>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditSubmit}
            sx={{ mt: 2 }}
            disabled={!hasImage || loading}
          >
            {loading ? <CircularProgress size={24} /> : "Save Changes"}
          </Button>
          <Button variant="outlined" onClick={handleModalClose} sx={{ mt: 2 }}>
            Cancel
          </Button>
        </Box>
      </Modal>

      {/* Delete Modal */}
      <Modal open={openDeleteModal} onClose={handleModalClose}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Delete Payment
          </Typography>

          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this payment?
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteSubmit}
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
          <Button variant="outlined" onClick={handleModalClose} sx={{ mt: 2 }}>
            Cancel
          </Button>
        </Box>
      </Modal>
      <ToastContainer />
    </Box>
  );
};

export default PopupMessagePage;
