import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, TextField, Typography, Container, CssBaseline, Box, CircularProgress } from "@mui/material";
import axiosClient from "../axiosClient";
import { useStateContext } from "../contexts/contextprovider";
import logo from "../images/logo.jpg"; 
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const { setUser, setToken } = useStateContext();

    const handleSubmit = (ev) => {
        ev.preventDefault();
        setIsLoading(true);
        const payload = {
            email: emailRef.current.value,
            password: passwordRef.current.value,
        };
        axiosClient
            .post("/user/login", payload)
            .then((response) => {
                if (response.data.status) {
                    setIsLoading(false);
                    setUser(response.data.data._id);
                    setToken(response.data.token);
                    toast.success('Login successful! Redirecting...');
                } else if (!response.data.status){
                    toast.error(response.data.msg);
                    setIsLoading(false);
                }
              })
            .catch(err => {
                toast.error(err);
                const response = err.response;
                if (response) {
                    console.log(response.data.errors);
                    console.error(response);
                } else {
                    // Handle other errors
                    console.error('An error occurred. Please try again.');
                }
            });
    };

    return (
        <Container component="main" disableGutters sx={{ width: '100vw', overflowX: 'hidden' }}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    minHeight: '100vh',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        flex: { xs: 1, sm: 0.3 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                    }}
                >
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            maxWidth: '80%',
                            height: 'auto',
                        }}
                    />
                    <Typography variant="h4" sx={{ mt: 2, mb: 2, fontWeight: 'bold', color: '#000000' }}>
                        Agora Academy
                    </Typography>
                </Box>

                <Box
                    sx={{
                        flex: { xs: 1, sm: 0.7 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                    }}
                >
                    <Box className="login-signup-form animated fadeinDown" sx={{ width: '100%', maxWidth: 400 }}>
                        <Typography component="h1" variant="h5" align="center">
                            Login To Your Account
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                inputRef={emailRef}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                inputRef={passwordRef}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                                sx={{
                                    backgroundColor: '#FEC619',
                                    color: '#333333',
                                    '&:hover': {
                                        backgroundColor: '#e6b000',
                                    },
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Login'}
                            </Button>
                            <Typography component="p" variant="body2" align="center" sx={{ mt: 2 }}>
                                Not Registered? <Link to="/register">Create a new account</Link>
                            </Typography>
                            <Typography component="p" variant="body2" align="center" sx={{ mt: 1 }}>
                                Forgot Password? <Link to="/forgetpassword">Reset it</Link>
                            </Typography>
                        </form>
                    </Box>
                </Box>
            </Box>
            <ToastContainer /> 
        </Container>
    );
}