import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../Components/OtherStuff/firebaseConfig";
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Fullscreen as FullscreenIcon,
  Upload as UploadIcon,
  Visibility as VisibilityIcon,
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  RestartAlt as RestartAltIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import axiosClient from "../axiosClient";
import { styled } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profileImage from "../images/avatar.jpg";

const PaperView = () => {
  const navigate = useNavigate();
  const { paperid } = useParams();
  const iframeRef = useRef(null);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [paperData, setPaperData] = useState({});
  const [paperEnrollData, setPaperEnrollData] = useState({});
  const [overlayVisible, setOverlayVisible] = useState(false);
  const overlayTimeoutRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [studentAnswer, setStudentAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("USER_ID");
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");
  const [isPaperLoading, setIsPaperLoading] = useState(true);
  const [isEnrollmentLoading, setIsEnrollmentLoading] = useState(true);
  const [updateAsnwer, setUpdateAnswer] = useState(true);

  const overlayTimeout = 10000;
  const screenshotKeys = ["PrintScreen", "F13", "Snapshot", "SysRq"];

  const UploadButton = styled(Button)({
    marginRight: 16,
  });

  useEffect(() => {
    const fetchPaperData = async () => {
      setIsPaperLoading(true);
      try {
        const response = await axiosClient.get(`/papers/${paperid}/${userId}`);
        if (response.data.status) {
          setPaperData(response.data.data.paper);
          setIsPaperLoading(false);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
          setIsPaperLoading(false);
        }
      } catch (error) {
        console.error("Error fetching paper data:", error);
        setIsPaperLoading(false);
      }
    };

    fetchPaperData();
  }, [paperid]);

  useEffect(() => {
    const fetchPaperEnrollData = async () => {
      setIsEnrollmentLoading(true);
      try {
        const response = await axiosClient.get(
          `/paper-enroll/${paperid}/${userId}`
        );
        if (response.data.status) {
          setPaperEnrollData(response.data.data);
          console.log(response.data.data);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching paper enrollment data:", error);
      } finally {
        setIsEnrollmentLoading(false);
      }
    };

    fetchPaperEnrollData();
  }, [paperid, userId, updateAsnwer]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axiosClient.get(
          `/comments?limit=100&page=1&paper_id=${paperid}`
        );
        if (response.data.status) {
          setComments(response.data.data.comments);
        } else if (!response.data.status) {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [paperid, newComment]);

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => setTime((prevTime) => prevTime + 1), 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (screenshotKeys.includes(event.key)) {
        showOverlay();
        console.log("Screenshots are not allowed.");
      }
    };

    const handleBlur = () => {
      showOverlay();
    };

    const handleFocus = () => {
      hideOverlay();
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const uploadFile = async (file, path) => {
    if (file) {
      const fileRef = ref(storage, path);
      try {
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        return url;
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file. Please try again.");
        throw new Error("Error uploading file. Please try again.");
      }
    } else {
      toast.error("No file selected.");
      throw new Error("No file selected.");
    }
  };

  const handleUploadAnswer = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setStudentAnswer(selectedFile);
    }
  };

  const clickUpload = async () => {
    if (!studentAnswer) {
      toast.error("No file selected.");
      return;
    }

    setLoading(true);
    setUpdateAnswer(false);
    try {
      const student_link = await uploadFile(
        studentAnswer,
        `pdfs/${studentAnswer.name}`
      );
      if (paperEnrollData) {
        const dataToSubmit = {
          _id: paperEnrollData._id,
          student_link,
          status: "to-do-teacher",
          student_answer_time: time,
        };

        await axiosClient
          .put("/paper-enroll/update", dataToSubmit)
          .then((response) => {
            if (response.data.status) {
              toast.success("Answer uploaded successfully");
              setUpdateAnswer(true);
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            console.error("error in uploading answer" + err);
          });
      }
      setOpen(false);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const showOverlay = () => {
    setOverlayVisible(true);
    clearTimeout(overlayTimeoutRef.current);
    overlayTimeoutRef.current = setTimeout(() => {
      hideOverlay();
    }, overlayTimeout);
  };

  const hideOverlay = () => {
    setOverlayVisible(false);
  };

  const handleFullScreen = () => {
    if (iframeRef.current.requestFullscreen) {
      iframeRef.current.requestFullscreen();
    } else if (iframeRef.current.mozRequestFullScreen) {
      iframeRef.current.mozRequestFullScreen();
    } else if (iframeRef.current.webkitRequestFullscreen) {
      iframeRef.current.webkitRequestFullscreen();
    } else if (iframeRef.current.msRequestFullscreen) {
      iframeRef.current.msRequestFullscreen();
    }
  };

  const handlePlayPause = () => {
    setIsRunning(!isRunning);
  };

  const handleReset = () => {
    setIsRunning(false);
    setTime(0);
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  const handleClickAnswerView = () => {
    navigate(`/viewanswer/${paperid}`);
  };

  const handleCommentSubmit = async () => {
    if (newComment.trim() === "") return;

    try {
      const response = await axiosClient.post("/comments/create", {
        paper_id: paperid,
        user_id: userId,
        comment: newComment,
      });

      if (response.data.status) {
        await setComments((prevComments) => [
          ...prevComments,
          response.data.data,
        ]);
        setNewComment("");
        toast.success("Comment submitted successfully");
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await axiosClient.delete(
        `/comments/delete/${commentId}`
      );
      if (response.data.status) {
        await setComments((prevComments) =>
          prevComments.filter((comment) => comment._id !== commentId)
        );
        toast.success("Comment deleted successfully");
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditComment = (commentId, commentText) => {
    setEditingCommentId(commentId);
    setEditedCommentText(commentText);
  };

  const handleSaveEditedComment = async () => {
    try {
      const response = await axiosClient.put(`/comments/update`, {
        _id: editingCommentId,
        comment: editedCommentText,
        user_id: userId,
      });

      if (response.data.status) {
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment._id === editingCommentId
              ? { ...comment, comment: editedCommentText }
              : comment
          )
        );
        setEditingCommentId(null);
        setEditedCommentText("");
        toast.success("Comment updated successfully!!!");
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <IconButton onClick={() => navigate("../")} sx={styles.backButton}>
            <ArrowBackIcon />
          </IconButton>
          <Box sx={styles.headerContainer}>
            <Box
              sx={{
                padding: "20px",
                backgroundColor: "#FFF5D9",
                borderRadius: "8px",
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                marginTop: "20px",
                width: "100%",
              }}
            >
              {/* Paper Title */}
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "#202124",
                  marginBottom: "8px",
                }}
              >
                {paperData.title?.replace(/\b\w/g, (char) =>
                  char.toUpperCase()
                ) || "Untitled Paper"}
              </Typography>

              {/* Teacher Name */}
              {paperData.teacher?.full_name && (
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#5f6368", marginBottom: "4px" }}
                >
                  by {paperData.teacher.full_name}
                </Typography>
              )}

              {/* Subject and Category */}
              {(paperData.subject?.name || paperData.category?.name) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginBottom: "4px",
                  }}
                >
                  {paperData.subject?.name && (
                    <Typography variant="subtitle1" sx={{ color: "#5f6368" }}>
                      Subject: {paperData.subject.name}
                    </Typography>
                  )}
                  {paperData.category?.name && (
                    <Typography variant="subtitle1" sx={{ color: "#5f6368" }}>
                      Category: {paperData.category.name}
                    </Typography>
                  )}
                </Box>
              )}

              {/* Unit and Duration */}
              {(paperData.folder?.name || paperData.duration) && (
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  {paperData.folder?.name && (
                    <Typography variant="subtitle1" sx={{ color: "#5f6368" }}>
                      Unit: {paperData.folder.name}
                    </Typography>
                  )}
                  {paperData.duration && (
                    <Typography variant="subtitle1" sx={{ color: "#5f6368" }}>
                      Duration: {paperData.duration}
                    </Typography>
                  )}

                  
                </Box>
                



              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ height: "100%" }}>
            <CardContent
              sx={{
                position: "relative",
                height: "60vh",
                p: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/* Show CircularProgress if loading */}
              {isPaperLoading || isEnrollmentLoading ? (
                <CircularProgress sx={{ position: "absolute", zIndex: 1 }} />
              ) : (
                <>
                  {/* Iframe to display the paper with disabled download options */}
                  <iframe
                    ref={iframeRef}
                    src={`${paperData.paper_link}#toolbar=0&navpanes=0&scrollbar=0`}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      zIndex: 0,
                    }}
                    title="PDF Viewer"
                  />

                  {/* Full Screen Button */}
                  <Button
                    onClick={handleFullScreen}
                    startIcon={<FullscreenIcon />}
                  >
                    Full Screen
                  </Button>

                  {/* Watermark */}
                  <Typography sx={styles.watermark}>Agora Academy</Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          {/* Timer Section */}
          <Paper
            sx={{
              ...styles.timerCard,
              boxShadow: 3,
              p: { xs: 1, sm: 2 },
              m: { xs: 1, sm: 2 },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                ...styles.timerTitle,
                fontSize: { xs: "1rem", sm: "1.25rem" },
              }}
            >
              Timer
            </Typography>
            <Typography
              variant="h3"
              sx={{
                ...styles.timer,
                fontSize: { xs: "2rem", sm: "3rem" },
                mt: { xs: 1, sm: 2 },
              }}
            >
              {formatTime(time)}
            </Typography>
            <Box
              sx={{
                ...styles.timerActions,
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 1, sm: 2 },
              }}
            >
              <Button
                onClick={handlePlayPause}
                startIcon={isRunning ? <PauseIcon /> : <PlayArrowIcon />}
                variant="contained"
                sx={{
                  ...styles.timerButton,
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                {isRunning ? "Pause" : "Start"}
              </Button>
              <Button
                onClick={handleReset}
                startIcon={<RestartAltIcon />}
                variant="outlined"
                sx={{
                  ...styles.timerButton,
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                Reset
              </Button>
            </Box>
          </Paper>

          {/* Paper Description Section */}
          <Paper sx={{ ...styles.timerCard, mt: 3, boxShadow: 3 }}>
            <Typography variant="h6" sx={styles.descriptionTitle}>
              About Paper
            </Typography>
            <Typography variant="body1" sx={styles.descriptionText}>
              {paperData.description}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ ...styles.answerCard, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, alignSelf: "flex-start" }}>
Answers              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Button
                  onClick={handleUploadAnswer}
                  startIcon={<UploadIcon />}
                  variant="contained"
                  color="primary"
                  disabled={
                    paperEnrollData && paperEnrollData.student_link
                      ? true
                      : false
                  }
                  sx={{ mb: 1, width: { sm: "auto" } }}
                >
                  {paperEnrollData && paperEnrollData.student_link
                    ? "Answer Uploaded"
                    : "Upload Answer"}
                </Button>
                <Button
                  onClick={handleClickAnswerView}
                  startIcon={<VisibilityIcon />}
                  variant="contained"
                  fullWidth={{ xs: true, sm: false }}
                  disabled={
                    paperEnrollData &&
                    paperEnrollData.status === "to-do-student"
                      ? true
                      : false
                  }
                  sx={{ width: { sm: "auto" } }}
                >
                  View Answer
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={styles.commentCard}>
            <Card
           
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", mb: 2, color: "text.primary" }}
                >
                  Comments
                </Typography>
                <List sx={{ maxHeight: "300px", overflow: "auto" }}>
                  {comments.map((comment) => (
                    <ListItem
                      key={comment._id}
                      sx={{
                        alignItems: "flex-start",
                        borderBottom: "1px solid #e0e0e0",
                        py: 1.5,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={comment.user?.profile_picture || profileImage}
                        />
                      </ListItemAvatar>
                      {editingCommentId === comment._id ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={editedCommentText}
                          onChange={(e) => setEditedCommentText(e.target.value)}
                          sx={{ mr: 2 }}
                        />
                      ) : (
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold", color: "text.primary" }}
                            >
                              {`${comment.user?.first_name} ${comment.user?.last_name}`}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              sx={{
                                color:
                                  comment.user_id === userId
                                    ? "primary.main"
                                    : "text.secondary",
                                fontStyle:
                                  comment.user_id === userId
                                    ? "italic"
                                    : "normal",
                              }}
                            >
                              {comment.comment}
                            </Typography>
                          }
                        />
                      )}
                      {comment.user_id === userId && (
                        <ListItemSecondaryAction>
                          {editingCommentId === comment._id ? (
                            <IconButton
                              edge="end"
                              aria-label="save"
                              color="primary"
                              onClick={handleSaveEditedComment}
                            >
                              <SendIcon />
                            </IconButton>
                          ) : (
                            <>
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                color="primary"
                                onClick={() =>
                                  handleEditComment(
                                    comment._id,
                                    comment.comment
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                color="error"
                                onClick={() => handleDeleteComment(comment._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
                </List>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                    
                    pt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Add a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    sx={{
                      mr: 2,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 50,
                      },
                    }}
                  />
                  <IconButton onClick={handleCommentSubmit} color="primary">
                    <SendIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Answer</DialogTitle>
        <DialogContent>
          <input type="file" accept="application/pdf" onChange={handleUpload} />
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <UploadButton
            onClick={clickUpload}
            startIcon={<UploadIcon />}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Upload
          </UploadButton>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Container>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlayText: {
    color: "white",
    fontSize: 24,
  },
  backButton: {
    marginRight: 16,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    mb: 3,
  },
  headerPaper: {
    flexGrow: 1,
  },
  headerTitle: {
    fontWeight: "bold",
  },
  headerAuthor: {
    color: "gray",
    flex: 1,
    textAlign: "left",
  },
  headerAuthorSmall: {
    color: "gray",
    mt: 0,
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fullScreenButton: {
    position: "absolute",
    bottom: -20,
    right: 19,
    zIndex: 2,
    backgroundColor: "gray",
    opacity: 0.9,
    color: "white",
    "&:hover": {
      backgroundColor: "gray",
      opacity: 1,
    },
  },
  watermark: {
    position: "absolute",
    opacity: 0.2,
    fontSize: 70,
    zIndex: 0,
  },
  timerCard: {
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "10px",
    boxShadow: 1,
    //backgroundColor: "#f5f5f5",
  },
  timerTitle: {
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#333",
    textTransform: "uppercase",
    fontSize: 25,
  },
  timer: {
    my: 2,
    fontSize: 50, // Increased font size for the timer
    color: "#333", // Dark color for the time
    fontWeight: "bold",
    letterSpacing: "2px",
  },
  timerActions: {
    display: "flex",
    gap: 2,
    mt: 2,
  },
  timerButton: {
    minWidth: 100,
    padding: "5px 10px",
    fontSize: "16px",
    borderRadius: "12px",
    textTransform: "none",
    boxShadow: "none",
  },
  descriptionTitle: {
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#333",
    textTransform: "uppercase",
  },
  descriptionText: {
    mt: 2,
    color: "#555",
    lineHeight: "1.6",
    fontSize: "16px",
  },
  answerCard: {
    mt: 3,
    p: 2,
  },
};

export default PaperView;
