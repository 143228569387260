import React from 'react'
import TileView from './TileView'

const FrontView = () => {
  return (
    <div>
        <TileView/>
    </div>
  )
}

export default FrontView