import React, { useState, useEffect } from "react";
import axiosClient from "../axiosClient";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TabPanel = ({ children, value, index }) => {
  return value === index ? <Box>{children}</Box> : null;
};

const CreateFolder = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [folderName, setFolderName] = useState("");
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [totalFolders, setTotalFolders] = useState(0);
  const [folderPagination, setFolderPagination] = useState({
    page: 0,
    limit: 25
  });

  useEffect(() => {
    axiosClient
      .get("/subject?limit=10&page=1")
      .then((response) => {
        const subjects = response.data.data.subjects || [];
        setSubjects(subjects);
        if (response.data.status) {
          const subjects = response.data.data.subjects || [];
          setSubjects(subjects);
        } else if (response.data.status === false) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Error fetching subjects", error);
        //toast.error("Failed to fetch subjects. Please try again.");
      });
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      setLoadingCategories(true);
      axiosClient
        .get(`/categories?limit=5&page=1&subject_id=${selectedSubject}`)
        .then((response) => {
          if (response.data.status) {
            const categories = (response.data.data.categories || []).filter(
              (category) => category.has_folder
            );
            setCategories(categories);
          } else if (response.data.status === false) {
            toast.error(response.data.msg);
          }
        })
        .catch((error) => {
          console.error("Error fetching categories", error);
          //toast.error("Failed to fetch categories. Please try again.");
        })
        .finally(() => {
          setLoadingCategories(false);
        });
    }
  }, [selectedSubject]);

  useEffect(() => {
    fetchData();
  }, [tabValue, folderPagination]);

  const fetchData = () => {
    setLoading(true);
    axiosClient
      .get(`/folders?limit=${folderPagination.limit}&page=${folderPagination.page + 1}`)
      .then((response) => {
        if (response.data.status) {
          const folders = response.data.data.folders || [];
          setFolders(folders);
          setTotalFolders(response.data.data.recordsTotal);
        } else if (response.data.status === false) {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Error fetching folders", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setFolderPagination({
      ...folderPagination,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setFolderPagination({
      ...folderPagination,
      limit: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const handleSubmit = async () => {
    setCreatingFolder(true);
    setError("");
    setFieldErrors({});

    const errors = {};
    if (!selectedSubject) errors.subject = true;
    if (!selectedCategory) errors.category = true;
    if (!folderName.trim()) errors.folderName = true;

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      setCreatingFolder(false);
      toast.error("Please fill in all required fields!!!");
      return;
    }

    try {
      const dataToSubmit = {
        name: folderName,
        subject_id: selectedSubject,
        category_id: selectedCategory,
      };

      const response = await axiosClient.post("/folders/create", dataToSubmit);
      console.log(response);
      if (response.data.status) {
        toast.success("Folder created successfully!");
        setSelectedSubject("");
        setSelectedCategory("");
        setFolderName("");
        fetchData();
      } else if (response.data.status === false) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error creating folder: " + error);
      toast.error("Failed to create folder. Please try again.");
    } finally {
      setCreatingFolder(false);
    }
  };

  const handleEdit = (folder) => {
    setSelectedFolder(folder);
    setSelectedSubject(folder.subject._id);
    setSelectedCategory(folder.category._id);
    setFolderName(folder.name);
    setOpenEdit(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const clickEdit = async () => {
    setLoading(true);
    try {
      const updatedFolder = {
        _id: selectedFolder._id,
        name: folderName,
        subject_id: selectedSubject,
        category_id: selectedCategory,
      };

      const response = await axiosClient.put(`/folders/update`, updatedFolder);

      if (response.data.status) {
        toast.success("Folder updated successfully!");
        fetchData();
        handleClose();
      } else if (!response.data.status) {
        toast.error(response.data.msg);
      }
    } catch (error) {
      setError(error.message);
      console.error("Failed to update folder. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setSelectedFolderId(id);
    setOpenDelete(true);
  };

  const clickDelete = async () => {
    setLoading(true);
    try {
      if (selectedFolderId) {
        await axiosClient
          .delete(`/folders/delete/${selectedFolderId}`)
          .then((response) => {
            if (response.data.status) {
              toast.success("Folder deleted successfully!");
              fetchData();
            } else if (!response.data.status) {
              toast.error(response.data.msg);
            }
          })
          .catch((err) => {
            setError("An error occurred. Please try again.");
            toast.error("Failed to delete folder. Please try again.");
            console.log("Error deleting folder: " + err);
          });
      }
    } catch (err) {
      setError(err);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
      setOpenDelete(false);
    }
  };

  const handleClose = () => {
    setSelectedFolder({});
    setSelectedFolderId("");
    setOpenEdit(false);
    setOpenDelete(false);
  };

  return (
    <Container>
      <IconButton onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center", mb: 4 }}>
        Folder Management
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        mb: 3,
      }}>
        <Tab label="Create Folder" sx={{ flex: 1 }}/>
        <Tab label="Created Folders" sx={{ flex: 1 }}/>
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            Create a New Folder
          </Typography>
          {error && (
            <Typography variant="body1" color="error" gutterBottom>
              {error}
            </Typography>
          )}
          <FormControl fullWidth margin="normal" error={fieldErrors.subject}>
            <InputLabel shrink htmlFor="select-subject" sx={{ background: '#fff', padding: '0 4px' }}>Select Subject *</InputLabel>
            <Select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              displayEmpty
            >
              {subjects.map((subject) => (
                <MenuItem key={subject._id} value={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            margin="normal"
            disabled={!selectedSubject || loadingCategories}
            error={fieldErrors.category}
          >
            <InputLabel shrink htmlFor="select-category" sx={{ background: '#fff', padding: '0 4px' }}>Select Category *</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Folder Name *"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            fullWidth
            margin="normal"
            error={fieldErrors.folderName}
          />
        </Box>

        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0077ED",
              paddingRight: 5,
              paddingLeft: 5,
              borderRadius: 50,
              color: "white",
              "&:hover": {
                backgroundColor: "#0071E3",
              },
            }}
            onClick={handleSubmit}
            disabled={creatingFolder}
          >
            {creatingFolder ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Create Folder"
            )}
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Box>
          <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
            Created Folders
          </Typography>

          {loading ? (
            <CircularProgress />
          ) : folders.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Subject</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Folder Name</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {folders.map((folder) => (
                      <TableRow key={folder._id}>
                        <TableCell>{folder.subject.name}</TableCell>
                        <TableCell>{folder.category.name}</TableCell>
                        <TableCell>{folder.name}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleEdit(folder)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDelete(folder._id)}
                            style={{ marginLeft: "1rem" }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalFolders}
                page={folderPagination.page}
                onPageChange={handleChangePage}
                rowsPerPage={folderPagination.limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </>
          ) : (
            <Typography variant="body1">No folders found.</Typography>
          )}
        </Box>
      </TabPanel>

      {/* Edit Folder Dialog */}
      <Dialog open={openEdit} onClose={handleClose}>
        <DialogTitle>Edit Folder</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Subject</InputLabel>
            <Select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              {subjects.map((subject) => (
                <MenuItem key={subject._id} value={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Select Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Folder Name"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={clickEdit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            If you click submit. This folder is deleted from this system
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => clickDelete()}
            color="primary"
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </Container>
  );
};

export default CreateFolder;
